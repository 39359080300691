import { EFCTrimsV2Response, EFCTrim } from '../models/EFC/EFCTrimsV2';
import { getEFCTrimsV2 } from './WebServicesAPI';
import { SuppressedTrims } from 'constants/VehicleConstants';

export type TrimData = {
  [year: string]: {
    [series: string]: {
      [trim: string]: EFCTrim;
    };
  };
};
export type TrimDataWithRegion = {
  [region: string]: TrimData;
};

const trimData: TrimDataWithRegion = {};

// SonarQube
const addTrimToData = (
  trim: EFCTrim,
  region: string,
  year: string,
  series: string
) => {
  // WFMB-265
  if (!SuppressedTrims[year] || !SuppressedTrims[year].includes(trim.code)) {
    trimData[region][year][series][trim.code] = trim;
  }
};

// transforms and merges trims response into year/series/trim result map
const mergeYearsResultIntoTrimData = (
  region: string,
  data: EFCTrimsV2Response
) => {
  if (!trimData[region]) {
    trimData[region] = {};
  }
  for (const yearE of data.years_list) {
    for (const seriesE of yearE.series_list) {
      if (!trimData[region]?.[yearE.year]) {
        trimData[region][yearE.year] = {};
      }
      if (!trimData[region][yearE.year]?.[seriesE.series]) {
        trimData[region][yearE.year][seriesE.series] = {};
      }
      seriesE.trims.forEach(trim =>
        addTrimToData(trim, region, yearE.year, seriesE.series)
      );
    }
  }
};

// caches results locally and returns a mapped response for easier manipulation
// will break apart api call into required subparts if we have partial data
// and only call for missing data.
export const getTrims = async ({
  years,
  region,
  series,
}: {
  region: string;
  series: string;
  years: string;
}) => {
  // first check we don't have the data already...
  const yearsA = years.split(',');
  const seriesA = series.split(',');

  const yearsToSearch: string[] = [];
  const seriesToSearch: string[] = [];

  // keep year/series elements we don't have stored
  for (const yearE of yearsA) {
    for (const seriesE of seriesA) {
      const missingYearOrSeries = !trimData?.[region]?.[yearE]?.[seriesE];
      if (missingYearOrSeries && !yearsToSearch.includes(yearE)) {
        yearsToSearch.push(yearE);
      }
      if (missingYearOrSeries && !seriesToSearch.includes(seriesE)) {
        seriesToSearch.push(seriesE);
      }
    }
  }

  // make api call
  if (yearsToSearch.length && seriesToSearch.length) {
    const yearsToQuery = yearsToSearch.join(',');
    const seriesToQuery = seriesToSearch.join(',');

    const result: { data: EFCTrimsV2Response } = await getEFCTrimsV2({
      series: seriesToQuery,
      years: yearsToQuery,
      region,
    });

    // merge data into trimData
    mergeYearsResultIntoTrimData(region, result.data);
  }

  return trimData[region];
};
