import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Header.module.scss';

type Props = {
  subHeader?: string;
};

const Header = ({ subHeader }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <h1 className={styles.text}>{t('pe_title')}</h1>
      {subHeader && <h2 className={styles.subText}>{t(subHeader)}</h2>}
    </div>
  );
};

export default Header;
