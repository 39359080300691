import ParentLocationService from '../../services/ParentLocationService';
import { isMobile } from '../../hooks/useBreakpoints';

export const getCampaignCode = (_isMobile: boolean = isMobile()) => {
  let campaignCode;
  const isToyota = ParentLocationService.getIsToyota();
  if (isToyota) {
    campaignCode = _isMobile
      ? process.env.REACT_APP_SLP_CAMPAIGN_CODE_MOBILE_TOYOTA
      : process.env.REACT_APP_SLP_CAMPAIGN_CODE_DESKTOP_TOYOTA;
  } else {
    const mobile = _isMobile;
    const isInventoryDetailsPage = ParentLocationService.getIsInventoryDetailsPage();
    // OATM-1619 Different campaign codes when within inventory details page
    if (mobile) {
      campaignCode = isInventoryDetailsPage
        ? process.env
            .REACT_APP_SLP_CAMPAIGN_CODE_MOBILE_BAT_INVENTORY_DETAILS_PAGE
        : process.env.REACT_APP_SLP_CAMPAIGN_CODE_MOBILE_BAT;
    } else {
      campaignCode = isInventoryDetailsPage
        ? process.env
            .REACT_APP_SLP_CAMPAIGN_CODE_DESKTOP_BAT_INVENTORY_DETAILS_PAGE
        : process.env.REACT_APP_SLP_CAMPAIGN_CODE_DESKTOP_BAT;
    }
  }
  return campaignCode;
};
