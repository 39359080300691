import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locale/lang-en.json';
import es from './locale/lang-es.json';

const resources = { en, es };

i18n.use(initReactI18next).init(
  {
    resources,
    keySeparator: '.',
    fallbackLng: 'en',
    lng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  },
  (err, t) => {
    if (err) {
      console.log('i18n. something went wrong loading', err);
    } else {
      t('key'); // -> same as i18next.t
    }
  }
);

export const changeLanguage = (lang: 'es' | 'en') => i18n.changeLanguage(lang);

export default i18n;
