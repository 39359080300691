import { Location } from 'history';
import { getQueryParams, createNewSearch } from '../utils/history';

export const createLocation =
  (pathname: 'estimator' | 'vehicleSelect', _params?: { [idx: string]: any }) =>
  (location: Location<any>) => {
    // need to omit year and trim from params...
    const params = getQueryParams();
    // retain just lang and zip...
    const newParams = {
      ..._params,
      ...(params.zip && { zip: params.zip }),
      ...(params.lang && { lang: params.lang }),
      ...(params.ref && { ref: params.ref }),
      ...(params.xact && { xact: params.xact }),
    } as { [idx: string]: string | number };
    return {
      ...location,
      pathname,
      search: createNewSearch(newParams),
    };
  };
