import React from 'react';
import { useInView } from 'react-intersection-observer';
import useEstimatorPageAAPageLoad from '../../../../../src/hooks/useEstimatorPageAAPageLoad';

const PageLoadAnalytics: React.FC = ({ children }) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  useEstimatorPageAAPageLoad(inView);

  return <div ref={ref}>{children}</div>;
};

export default PageLoadAnalytics;
