import axios from 'axios';

export const fetchVehicleDetails = (vin: string) => {
  const url = `${process.env.REACT_APP_PE_SIT_WS_BASE_URL}/dd365/locate`;
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      vin: `${vin}`,
    },
  });
};

export const fetchAccessoryDetails = (
  disclaimerCode: string,
  modelYear: string,
  series: string
) => {
  const url = `${process.env.REACT_APP_PE_SIT_WS_BASE_URL}/dd365/disclaimer`;
  return axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      disclaimerCode,
      modelYear,
      series,
    },
  });
};
