import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useStore } from '../stores';
import * as AnalyticsService from '../utils/Analytics';

export const useAnalyticsAAPageLoad = (
  PAGE_TITLE: string,
  awaitTdaCode = false
) => {
  const {
    store: { parentLocationUrlSet },
    zipCodeStore: { tdaCode },
  } = useStore();
  const [fired, setFired] = useState(false);
  const [_parentLocationSet, setParentLocation] = useState(
    parentLocationUrlSet
  );

  useEffect(() => setParentLocation(parentLocationUrlSet), [
    parentLocationUrlSet,
  ]);

  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    const fireTag = () => {
      let block = AnalyticsService.extendWithGlobalBlock({}, PAGE_TITLE);
      block = AnalyticsService.getToolBlock(block);
      AnalyticsService.fireAAPageLoad(block);
      setFired(true);
    };

    // WFMB-214: need to wait for parent Location to be set to make sure we have correct campaignCode
    if (
      !fired &&
      inView &&
      _parentLocationSet &&
      // WFMB-212: if on vehicle selction page (awaitTdaCode === true), need to await tda code to come in before firing
      (!awaitTdaCode || (awaitTdaCode && tdaCode !== ''))
    ) {
      fireTag();
    }
  }, [
    fired,
    setFired,
    PAGE_TITLE,
    awaitTdaCode,
    _parentLocationSet,
    tdaCode,
    inView,
  ]);

  return ref;
};
