import { EFCV2YearsList } from './EFCV2YearsList';

export type EFCColorsV2Exterior = {
  code: string; // "03T3",
  title: string; // "Ruby Flare Pearl [extra_cost_color]",
  type: string; // "exteriorcolor",
  price: {
    msrp: string; // "425"
  };
  applicable: Array<{
    trim: string; // "2515",
    override: {
      images: {
        car_jelly_image: string; // "/config/pub/static/images/TOY/2020/camry/exteriorcolor/carjellyimage/..._MY20_Camry_US_LE_AWD_03T3_002.png"
      };
    };
  }>;
  valid: boolean; // true,
  title_disclaimer: string; // "extra_cost_color",
  hex_value: string[]; // ["740000"]
};

export type EFCColorsSeriesList = {
  series: string; // 'sienna';
  colors: {
    exterior: EFCColorsV2Exterior[];
    interior: any;
    color_combinations: any;
  };
};

// if too many series are send in request, it can respond with an empty string ""
export type EFCColorsV2ResponseError = '';

export type EFCColorsV2Response = {
  years_list: Array<{
    year: string; // "2020"
    series_list: EFCColorsSeriesList[];
  }>;
};

export const EFCColorsV2IsSuccess = (
  response: EFCColorsV2Response | EFCColorsV2ResponseError
): response is EFCColorsV2Response => {
  return (response as EFCColorsV2Response).years_list !== undefined;
};

export type EFCColorsProcessedSeriesList = {
  series: string; // 'sienna';
  exterior: EFCColorsV2Exterior; // only one exterior filtered by efcmodelmap
};

// processed result for vehicle select page...
export type EFCColorsV2ProcessedResponse = EFCV2YearsList<EFCColorsProcessedSeriesList>;
