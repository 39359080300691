import React from 'react';
import cx from 'classnames';
import { useStore } from '../../../../stores';
import styles from './confirmModal.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import handlerStore from '../../../../stores/EstimatorPage/estimatorPageHandlers';

const ConfirmModal: React.FC = () => {
  const { t } = useTranslation();
  const {
    modalStore: {
      displayConfirmOfferChangeModal,
      displayConfirmGradeChangeModal,
      closeConfirmModal,
    },
  } = useStore();
  const confirmHandler = displayConfirmOfferChangeModal
    ? handlerStore.confirmOfferChange
    : handlerStore.confirmGradeChange;
  const display =
    displayConfirmOfferChangeModal || displayConfirmGradeChangeModal;
  return (
    <>
      <div
        className={cx(styles.modal, {
          [styles.open]: display,
        })}
      >
        <div className={cx(styles.modalContent, 'modal-content')}>
          <div className={styles.modalHeader}>
            <div className={styles.closeModal}>
              <button
                className={styles.closeButton}
                onClick={closeConfirmModal}
              >
                <span className={styles.closeSpan} />
              </button>
            </div>
            <h1 className={styles.title}>Configuration Change</h1>
          </div>
          <div className={styles.modalBody}>
            <p className={styles.description}>{t('confirm')}</p>
            <div className={styles.buttonWrapper}>
              <button className={styles.actionButton} onClick={confirmHandler}>
                {t('btn_continue')}
              </button>
              <button
                className={styles.actionButton}
                onClick={closeConfirmModal}
              >
                {t('btn_cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {display && <div className={styles.overlay} />}
    </>
  );
};

export default observer(ConfirmModal);
