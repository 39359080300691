import i18n from 'i18next';
import { observable, decorate } from 'mobx';
import { getDealers } from '../../services/WebServicesAPI';

import { validateZipCodeInput } from '../../utils/zipCode';
import { navigateToPage } from '../GlobalStore/utils';
import { extractDataFromDealerResponse } from './utils';
import GlobalStore from '../GlobalStore';
import { history } from '../../stores/index';

import * as AnalyticsService from '../../utils/Analytics';
import * as PostMesssageService from '../../services/PostMessageService';

import { getQueryParams } from '../../utils/history';
import { Category } from '../../models/VIEW/ZipCodeStore';
import { createCategoriesForVehicleSelectPage } from './transforms';

const ZipCodeStore = {
  currentJellyCount: 0,
  vehcileSelectPageIsLoaded: 0,
  vehicleSelectPageImageOnload: () => {
    // update height on each image on load...
    PostMesssageService.sendHeight();
  },
  inputZipCode: '',
  inputZipCodeValid: false,
  inputZipCodeDisplayValidation: false,
  isZipValid: true,
  regionCode: '', // for get series data
  stateCode: '', // for getting offers
  tdaCode: '', // for getting offers
  pricingArea: '', // for...
  dealersFromResponse: [],
  categories: [] as Category[], // vehicle select page
  zipCodeOnSubmit: async () => {
    // validate first
    ZipCodeStore.isZipValid = validateZipCodeInput(ZipCodeStore.inputZipCode);
    if (!ZipCodeStore.isZipValid) {
      return;
    }

    try {
      GlobalStore.isFetching = true;
      await ZipCodeStore.getRegionAndSeriesDataForVehicleSelectPage();
      const params = getQueryParams();
      PostMesssageService.sendZip(ZipCodeStore.inputZipCode, true); // TRUE INDICATES A SEND ONCE, WHERE THE SAME MESSAGE IS NOT TO BE SENT BACK, NO LOOP PLEASE.
      ZipCodeStore.fireLinkBlock();
      navigateToPage(history, 'vehicleSelect', {
        zip: ZipCodeStore.inputZipCode,
        ...params,
      });
    } catch (error) {
      // networks errors caught and handled in child async methods
    }
  },
  fireLinkBlock: () => {
    let block = ZipCodeStore.extendWithGlobalBlock({
      link_text: i18n.t('btn_submit'),
      link_action: 'AA-link',
    });
    block = AnalyticsService.getToolBlock(block);
    AnalyticsService.fireAALink(block);
  },
  extendWithGlobalBlock: (block: any) => {
    block.content_section = 'pe_enter_zip_code';
    return block;
  },
  setInputZipCode: (zipCode: string) => {
    ZipCodeStore.inputZipCode = zipCode;
  },
  getRegionAndSeriesDataForVehicleSelectPage: async () => {
    GlobalStore.isFetching = true;
    await ZipCodeStore.getZipCode(ZipCodeStore.inputZipCode);
    await ZipCodeStore.getCategoriesForVehicleSelectPage();
    GlobalStore.isFetching = false;
  },
  // gets categories if we don't have them already...
  navigateToVehicleSelectPage: async () => {
    if (ZipCodeStore.categories.length > 0) {
      return;
    }
    GlobalStore.isFetching = true;
    await ZipCodeStore.getCategoriesForVehicleSelectPage();
    GlobalStore.isFetching = false;
  },
  getZipCode: async (zipcode: string) => {
    try {
      const dealerResults = await getDealers(zipcode);
      const {
        regionCode,
        stateCode,
        tdaCode,
        pricingArea,
      } = extractDataFromDealerResponse(dealerResults.data);
      ZipCodeStore.regionCode = regionCode;
      ZipCodeStore.stateCode = stateCode;
      ZipCodeStore.tdaCode = tdaCode;
      ZipCodeStore.pricingArea = pricingArea;
    } catch (error) {
      GlobalStore.serviceError(error);

      // navigate to zip input page
      navigateToPage(history, '', {});

      // throw error so calling functions
      throw new Error();
    }
  },
  getCategoriesForVehicleSelectPage: async () => {
    try {
      ZipCodeStore.categories = await createCategoriesForVehicleSelectPage(
        ZipCodeStore.regionCode
      );
    } catch (error) {
      GlobalStore.serviceError(error);
    }
  },
  setZipCodeFromQueryString: () => {
    const params = getQueryParams();
    ZipCodeStore.inputZipCode = params.zip as string;
  },
  vehicleSelectorPageBoostrap: () => {
    try {
      ZipCodeStore.setZipCodeFromQueryString();
      ZipCodeStore.getRegionAndSeriesDataForVehicleSelectPage();
    } catch (error) {
      // error already handles in called functions
    }
  },
  zipChangeCB: (zipCode: string) => {
    ZipCodeStore.setInputZipCode(zipCode);
    ZipCodeStore.zipCodeOnSubmit();
  },
};

decorate(ZipCodeStore, {
  inputZipCode: observable,
  inputZipCodeValid: observable,
  inputZipCodeDisplayValidation: observable,
  isZipValid: observable,
  regionCode: observable,
  stateCode: observable,
  tdaCode: observable,
  pricingArea: observable,
  categories: observable,
  dealersFromResponse: observable,
});

export default ZipCodeStore;
