import { OfferForView } from '../Factories/offerFactory';

export enum Subvention {
  NO,
  YES,
  BOTH, // misc offers can match with both subvented and non subvented rate offers
}

export const isOfferSubvented = (offer: OfferForView) =>
  offer.isSubvented !== undefined &&
  (offer.isSubvented === Subvention.YES || Subvention.BOTH);

export const mapBool2IsSubvented = (_isSubvented: boolean) =>
  _isSubvented ? Subvention.YES : Subvention.NO;

export const mapIsSubvented2Bool = (_isSubvented?: Subvention) =>
  _isSubvented === Subvention.BOTH || _isSubvented === Subvention.YES;
