import qs from 'query-string';
import { History } from 'history';

// navigate to new page, creating new query params if needed
export const navigateToPage = (
  history: History,
  pathname: string,
  newSearchParams?: { [idx: string]: string | null | undefined }
) => {
  const search = qs.stringify({ ...newSearchParams });
  const location = {
    pathname,
    search,
  };
  history.push(location);
};
