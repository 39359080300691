export type BrandServices = 'dis' | 'efc' | 'pews';
export type Brands = {
  [id: string]: {
    [idx: string]: string;
  };
};

const brands: Brands = {
  toyota: {
    dis: '1',
    efc: 'TOY',
    pews: 'Toyota',
  },
  scion: {
    dis: '3',
    efc: 'SCI',
    pews: 'Scion',
  },
  lexus: {
    dis: '2',
    efc: 'LEX',
    pews: 'Lexus',
  },
};

export default brands;
