import React from 'react';
import { useTranslation } from 'react-i18next';

import { Bullet } from '../../../../../../models/PEMS/PEMMarketingData';
import styles from './marketingDetails.module.scss';

type MarketingDetailsProps = {
  marketingData: {
    bullets: Bullet[];
    disclaimer: string[];
    description: string;
    offerImage: string;
    offerImageAlt: string;
  };
  scrollToDisclaimer: () => void;
};

const MarketingDetails: React.FC<MarketingDetailsProps> = ({
  marketingData,
  scrollToDisclaimer,
}) => {
  const { t } = useTranslation();
  return (
    <ul className={styles.offerDetailsModalList}>
      {marketingData.bullets.map(bullet =>
        bullet.link ? (
          <li key={bullet.text}>
            <a href={bullet.link}>{bullet.text}</a>
          </li>
        ) : (
          <li key={bullet.text}>
            <p>{bullet.text}</p>
          </li>
        )
      )}
      {marketingData.disclaimer.length > 0 && (
        <li>
          <button onClick={scrollToDisclaimer}>
            {t('see_below_for_full_terms')}
          </button>
        </li>
      )}
    </ul>
  );
};

export default MarketingDetails;
