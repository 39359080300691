export type CreditScoreConst = {
  id: number;
  label: string;
  labelSET: string;
  scoreSET: string;
  score: string;
  tier: string;
  isOnlySET: boolean;
};

export type CreditScore = {
  id: number;
  label: string;
  score: string;
  tier: string;
};

export const CreditScoreConstant: CreditScoreConst[] = [
  {
    id: 0,
    label: 'credit_score_text.excellent',
    labelSET: 'credit_score_text.excellent',
    scoreSET: '780+',
    score: '720+',
    tier: '1+',
    isOnlySET: false,
  },
  {
    id: 1,
    label: 'credit_score_text.great',
    labelSET: '',
    scoreSET: '779-760',
    score: '719-690',
    tier: '1',
    isOnlySET: false,
  },
  {
    id: 2,
    label: 'credit_score_text.very_good',
    labelSET: '',
    scoreSET: '759-740',
    score: '689-670',
    tier: '2',
    isOnlySET: false,
  },
  {
    id: 3,
    label: 'credit_score_text.good',
    labelSET: '',
    scoreSET: '739-720',
    score: '669-650',
    tier: '3',
    isOnlySET: false,
  },
  {
    id: 4,
    label: 'credit_score_text.fair',
    labelSET: '',
    scoreSET: '719-700',
    score: '649-630',
    tier: '4',
    isOnlySET: false,
  },
  {
    id: 5,
    label: 'credit_score_text.poor',
    labelSET: '',
    scoreSET: '699-680',
    score: '629-610',
    tier: '5',
    isOnlySET: false,
  },
  {
    id: 6,
    label: 'credit_score_text.very_poor',
    labelSET: '',
    scoreSET: '679-660',
    score: '609-580',
    tier: '6',
    isOnlySET: false,
  },
  {
    id: 7,
    label: 'credit_score_text.extremely_poor',
    labelSET: '',
    scoreSET: '659-640',
    score: '579-520',
    tier: '7',
    isOnlySET: false,
  },
  {
    id: 8,
    label: '',
    labelSET: '',
    scoreSET: '639-620',
    score: '',
    tier: '8',
    isOnlySET: true,
  },
  {
    id: 9,
    label: '',
    labelSET: '',
    scoreSET: '619-600',
    score: '',
    tier: '9',
    isOnlySET: true,
  },
  {
    id: 10,
    label: '',
    labelSET: '',
    scoreSET: '599-580',
    score: '',
    tier: '10',
    isOnlySET: true,
  },
  {
    id: 11,
    label: '',
    labelSET: 'Poor',
    scoreSET: '<580',
    score: '',
    tier: '11',
    isOnlySET: true,
  },
];

export const getCreditScoreConstant = (region: string) => {
  const filteredCreditScoreList = CreditScoreConstant.filter(
    creditScore =>
      (region !== '500' && !creditScore.isOnlySET) || region === '500'
  );

  return filteredCreditScoreList.map(creditScore => ({
    id: creditScore.id,
    label: region === '500' ? creditScore.labelSET : creditScore.label,
    score: region === '500' ? creditScore.scoreSET : creditScore.score,
    tier: creditScore.tier,
  }));
};

export const getCreditScoreItemById = (id: number, region: string) =>
  getCreditScoreConstant(region).find(element => element.id === id);
