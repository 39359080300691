import cookie from 'js-cookie';
import i18n from 'i18n';

import ZipCodeStore from '../../stores/ZipCodeStore';

import AnalyticsConstants, { TagType } from '../../constants/Analytics';
import { OfferForView } from '../../stores/EstimatorPage/Factories/offerFactory';

import { getQueryParams } from '../../utils/history';
import { Dealer } from '../../models/VIEW/RequestAQuote';
import { getCampaignCode } from './campaignCode';
import { Tab } from 'models/VIEW/ViewOffersTab';

export const PAGE_TITLE_ZIPCODE = 'pe_enter_zip_code';
export const PAGE_TITLE_VEHICLE_SELECT = 'model_selector';
export const PAGE_TITLE_ESTIMATOR_LEASE = 'pe_form_lease';
export const PAGE_TITLE_ESTIMATOR_FINANCE = 'pe_form_finance';
export const PAGE_TITLE_OFFER_MODAL = 'offer_modal';
export const PAGE_TITLE_CONTACT_DEALER_MODAL_SUCCESS = 'pe_complete_modal';
export const PAGE_TITLE_CONTACT_DEALER_MODAL = 'contact_dealer_modal';

const _TOOLNAME = 'payment_estimator';

const offerBlockObject = {
  offer_type: '',
  offer_expired_date: '',
  offer_series_brand: '',
  offer_term: '',
  offer_rate: '',
  offer_amtdue: '',
  offer_monthly: '',
  offer_cashback: '',
  offer_series_number: '',
  offer_series_msrp: '',
  offer_id: '',
};

export const fireTag = (tag: TagType, blocks: any) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (typeof window.fireTag === 'function') {
    window.fireTag(tag, blocks);
  } else {
    for (const prop of Object.keys(blocks)) {
      if (blocks[prop] === undefined) {
        delete blocks[prop];
      }
    }
    console.table(blocks);
  }
};

export const getLinkBlock = (tab: Tab) => {
  return {
    link_button_action: i18n.t(tab).toLowerCase(),
    link_text: i18n.t(tab).toLowerCase(),
    link_trigger: 'aa-link',
    link_type: 'link',
    link_type_title: `change to ${i18n.t(tab).toLowerCase()}`,
  };
};

export const getToolBlock = (params: any = {}) => {
  const blocks = params;
  blocks.tool_name = _TOOLNAME;
  blocks.tier = '2';
  blocks.formcampaign_code = getCampaignCode();
  blocks.tda_code = ZipCodeStore.tdaCode;
  return blocks;
};

export const getOfferBlock = (block: any, offer: OfferForView, brand: any) => {
  block.offer_type = offer.offerType;
  block.offer_expired_date = offer.endDate;
  block.offer_series_brand = brand;
  if (offer.terms) {
    block.offer_term = offer.terms;
  }
  if (offer.rate) {
    block.offer_rate = offer.rate;
  }
  if (offer.dueAtSigning) {
    block.offer_amtdue = offer.dueAtSigning;
  }
  if (offer.payment) {
    block.offer_monthly = offer.payment;
  }
  if (offer.modelCode) {
    block.offer_series_number = offer.modelCode;
  }
  if (offer.totalMsrp) {
    block.offer_series_msrp = offer.totalMsrp.toString();
  }
  return block;
};

export const getDealerBlock = (
  block: any,
  selectedDealer: Dealer,
  brand: any
) => {
  block.dealer_code = selectedDealer.code;
  block.dealer_name = selectedDealer.name;
  block.dealer_zipcode = selectedDealer.contact.zip;
  block.dealer_city = selectedDealer.contact.city;
  block.dealer_state = selectedDealer.contact.state;
  block.dealer_brand = brand;

  return block;
};

export const getDealerSearchBlock = (
  block: any,
  brand: any,
  dealerSearch: any
) => {
  block.dealer_search_term = dealerSearch.zip;
  block.dealer_search_type = 'zip';
  block.dealer_search_results_count = dealerSearch.search_count;
  block.dealer_search_brand = brand;

  return block;
};

export const createOffersBlock = (
  selectedOffers: OfferForView[],
  block: { [idx: string]: any },
  brand: any
) => {
  const offerObj: { [idx: string]: any } = { ...offerBlockObject };
  let count = 0;
  selectedOffers.forEach(offer => {
    let commaVal = ',';
    if (selectedOffers.length < 2) {
      commaVal = '';
    }

    if (selectedOffers.length > 1 && selectedOffers.length - count < 2) {
      commaVal = '';
    }
    offerObj.offer_series_brand = brand;
    offerObj.offer_type += `${offer.offerType}${commaVal}`;
    offerObj.offer_expired_date += `${offer.endDate}${commaVal}`;

    const termValue = offer.terms || '';
    const rateValue = offer.rate || '';
    const dasValue = offer.dueAtSigning || '';
    const paymentValue = offer.payment || '';
    const cashbackValue = offer.amount || '';
    const modelCodeValue = offer.modelCode || '';
    const msrpValue = offer.totalMsrp || '';
    const offerId = offer.tdaOfferId || '';

    offerObj.offer_term += `${termValue.toString()}${commaVal}`;
    offerObj.offer_rate += `${rateValue.toString()}${commaVal}`;
    offerObj.offer_amtdue += `${dasValue.toString()}${commaVal}`;
    offerObj.offer_monthly += `${paymentValue.toString()}${commaVal}`;
    offerObj.offer_cashback += `${cashbackValue.toString()}${commaVal}`;
    offerObj.offer_series_number += `${modelCodeValue}${commaVal}`;
    offerObj.offer_series_msrp += `${msrpValue.toString()}${commaVal}`;
    offerObj.offer_id += `${offerId}${commaVal}`;

    count++;
  });
  for (const prop of Object.keys(offerObj)) {
    block[prop] = offerObj[prop];
  }
  return block;
};

export const fireAALink = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.AALINK, blocks);
};

export const fireAAPageLoad = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.AAPAGELOAD, blocks);
};

export const fireAASubPageLoad = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.AASUBPAGELOAD, blocks);
};

export const fireKMPageLoad = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMPAGEPAGELOAD, blocks);
};

export const fireKMLink = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMLINK, blocks);
};

export const fireKMLink1 = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMLINK1, blocks);
};

export const fireKMLink2 = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMLINK2, blocks);
};

// BAT-4574
export const fireKMPEShown = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMPESHOWN, blocks);
};

// OATM-1533
export const fireAACcomponentView = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.AACOMPONENTVIEW, blocks);
};

export const fireKMPEStart = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMPESTART, blocks);
};

export const fireKMPEComplete = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMPECOMPLETE, blocks);
};

// BAT-4574
export const fireKMCADShown = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMCADSHOWN, blocks);
};

export const fireKMCADStart = (blocks: any) => {
  fireTag(AnalyticsConstants.TAGS.KMCADSTART, blocks);
};

export const fireKMCADComplete = (blocks: any) => {
  blocks.transaction_id = createRandomIdentifier();
  fireTag(AnalyticsConstants.TAGS.KMCADCOMPLETE, blocks);
};

// BAT-4600
export const createRandomIdentifier = () => {
  const xactCookie = cookie.get('xact');
  const { xact } = getQueryParams();
  if (xactCookie) {
    return xactCookie;
  } else if (xact) {
    return xact.toString();
  } else {
    return `${(crypto.getRandomValues(new Uint8Array(1))[0]/255)}`;
  }
};

export const extendWithGlobalBlock = (block: any, PAGE_TITLE: string) => {
  const { zip } = getQueryParams();
  block.zipcode = zip;
  block.content_section = PAGE_TITLE;
  return block;
};
