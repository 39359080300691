import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { observer } from 'mobx-react-lite';

import { useTranslation } from 'react-i18next';
import { useStore } from '../../../../stores';
import { useScrollTo } from '../../../../hooks/useScrollTo';
import ModalBase from 'components/ModalBase';
import { Disclosures } from '../../../../models/VIEW/ViewOffersTab';
import { onEnterPress } from 'utils/onEnterPress';
import { sendHeight } from 'services/PostMessageService';
import {
  baseMsrpDisclosure,
  dapDisclosure,
  msrpDisclosure,
  totalAmountAsBuiltDisclosure,
} from 'constants/Disclosures';
import { getQueryParams } from 'utils/history';
import styles from './DisclaimerModal.module.scss';

type Disclaimer = {
  id: string;
  description: string;
};

const getDisclaimerDescription = (
  disclosures: Disclosures,
  stringID: string
) => {
  return (
    (disclosures.filter(item => item.disclosureType === stringID)[0] || {})
      .disclosureValue || 'Disclaimer Not Available at this time.'
  );
};

const disclosures = (disclosures: Disclosures) => [
  {
    id: '1',
    description: getDisclaimerDescription(
      disclosures,
      'creditRatingDisclosure'
    ),
  },
  {
    id: '2',
    description: getDisclaimerDescription(disclosures, 'cashDownDisclosure'),
  },
  {
    id: '3',
    description: getDisclaimerDescription(disclosures, 'termDisclosure'),
  },
  {
    id: '4',
    description: getDisclaimerDescription(disclosures, 'aprInputDisclosure'),
  },
  {
    id: '5',
    description: getDisclaimerDescription(
      disclosures,
      'estimatedTradeInValueDisclosure'
    ),
  },
  {
    id: '6',
    description: getDisclaimerDescription(disclosures, 'mileageDisclosure'),
  },
  {
    id: '7',
    description: getDisclaimerDescription(disclosures, 'msrpDisclosure'),
  },
  {
    id: '8',
    description: getDisclaimerDescription(
      disclosures,
      'cashOutOfPocketDisclosure'
    ),
  },
];

const DisclaimerModal: React.FC = () => {
  const { t } = useTranslation();
  const modalBodyRef = React.useRef(null);
  const {
    modalStore: {
      displayDisclaimerModal,
      closeDisclaimerModal,
      disclosuresForModal,
      disclaimerId,
    },
    estimatorStore: { vehicleAccessoryDisclaimers },
  } = useStore();
  useScrollTo(modalBodyRef, `disclaimerModalElement-${disclaimerId}`);

  const [disclosuresToDisplay, setDisclosuresToDisplay] =
    useState<Disclaimer[]>();

  useEffect(() => {
    if (window.location.href.includes('vehicleSelect')) {
      setDisclosuresToDisplay([{ id: '1', description: msrpDisclosure }]);
    } else {
      const primaryDisclosures = disclosures(disclosuresForModal);

      const { isTaab } = getQueryParams();

      primaryDisclosures.push({
        id: '9',
        description: dapDisclosure,
      });

      primaryDisclosures.push({
        id: '10',
        description: baseMsrpDisclosure,
      });

      if (isTaab === 'true') {
        primaryDisclosures.push({
          id: '11',
          description: totalAmountAsBuiltDisclosure,
        });
      }

      const accessoryDisclosures = vehicleAccessoryDisclaimers.map(
        disclaimer => ({
          id: disclaimer.id,
          description: disclaimer.description,
        })
      );
      setDisclosuresToDisplay(primaryDisclosures.concat(accessoryDisclosures));
    }
  }, [disclosuresForModal, vehicleAccessoryDisclaimers]);

  useEffect(() => {
    sendHeight();
  });

  return (
    <ModalBase
      isOpen={displayDisclaimerModal}
      darkOverlay
      className={styles.customDialog}
    >
      <div className={cx(styles.modal)}>
        <div className={cx(styles.modalContent, 'modal-content')}>
          <div className={styles.modalHeader}>
            <h1 className={styles.title}>{t('disclosures')}</h1>
            <div className={styles.closeModal}>
              <button
                className={styles.closeButton}
                onClick={closeDisclaimerModal}
                onKeyPress={e => {
                  onEnterPress(e, () => closeDisclaimerModal());
                }}
                tabIndex={0}
              >
                &#215;
              </button>
            </div>
          </div>
          <section className={styles.section} ref={modalBodyRef}>
            <ul className={styles.list}>
              {disclosuresToDisplay?.map((item: Disclaimer) => (
                <li
                  className={styles.listElement}
                  key={item.id}
                  data-key={item.id}
                  id={`disclaimerModalElement-${item.id}`}
                >
                  <p className={styles.article}>
                    <span className={styles.disclosureId}>{item.id}</span>
                    <span
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></span>
                  </p>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
    </ModalBase>
  );
};

export default observer(DisclaimerModal);
