import React from 'react';

import cx from 'classnames';
import styles from './TextInput.module.scss';

type Props = {
  describedBy: string;
  required?: boolean;
  onChange: React.ChangeEventHandler;
  error?: boolean;
  value: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput: React.FC<Props> = ({
  required = false,
  error = false,
  describedBy,
  onChange,
  value,
  id,
}) => (
  <input
    className={cx(styles.input, {
      [styles.invalid]: error,
    })}
    id={id}
    type="text"
    aria-describedby={describedBy}
    aria-required={required}
    onChange={onChange}
    value={value}
  />
);

export default TextInput;
