import React from 'react';
import cx from 'classnames';
import styles from './Cta.module.scss';
import { Link } from 'react-router-dom';

type ButtonVariant =
  | 'redBackground'
  | 'redArrow'
  | 'toggleArrow'
  | 'skip'
  | 'defaultCta'
  | 'multiSelect'
  | 'cancel'
  | 'withRedUnderline'
  | 'withBlackUnderline';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  fullWidth?: boolean;
  href?: string;
  inactive?: boolean;
  onClick?: () => void;
  toggled?: boolean;
  variant?: ButtonVariant;
}

const Cta = (props: ButtonProps) => {
  const {
    children,
    className,
    disabled,
    fullWidth,
    href,
    inactive = false,
    toggled,
    variant,
    onClick = () => {}, // tslint-disable-line
    ...rest
  } = props;

  return (
    <>
      {!href && (
        <button
          disabled={disabled}
          className={cx([
            className,
            {
              [styles.button]: true,
              [styles.defaultCta]: variant === 'redBackground',
              [styles.redArrow]: variant === 'redArrow',
              [styles.toggleArrow]: variant === 'toggleArrow',
              [styles.toggled]: toggled,
              [styles.fullWidth]: fullWidth,
              [styles.withUnderline]:
                variant === 'withRedUnderline' ||
                variant === 'withBlackUnderline',
              [styles.withRedUnderline]: variant === 'withRedUnderline',
              [styles.withBlackUnderline]: variant === 'withBlackUnderline',
              [styles.inactive]: inactive,
            },
          ])}
          onClick={() => onClick()}
          {...rest}
        >
          {children}
        </button>
      )}
      {href && (
        <Link
          to={href}
          className={cx([
            className,
            {
              [styles.button]: true,
              [styles.defaultCta]: variant === 'redBackground',
              [styles.redArrow]: variant === 'redArrow',
              [styles.toggleArrow]: variant === 'toggleArrow',
              [styles.toggled]: toggled,
              [styles.fullWidth]: fullWidth,
              [styles.withUnderline]:
                variant === 'withRedUnderline' ||
                variant === 'withBlackUnderline',
              [styles.withRedUnderline]: variant === 'withRedUnderline',
              [styles.withBlackUnderline]: variant === 'withBlackUnderline',
              [styles.inactive]: inactive,
            },
          ])}
          onClick={() => onClick()}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default Cta;
