import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';

import handlerStore from '../../../../../../stores/EstimatorPage/estimatorPageHandlers';
import Cta from '../../../../../../components/Cta';
import { Tab } from 'models/VIEW/ViewOffersTab';
import FinanceTypeModal from '../FinanceTypeModal';
import { fireInfoCtaClick } from 'utils/Analytics/estimatorAnalytics';
import styles from './Toggle.module.scss';

const Toggle: React.FC = () => {
  const { t } = useTranslation();

  const {
    estimatorStore: { payOptForm, selectedTab },
  } = useStore();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleOptions = [
    { label: t('lease'), value: 'lease' },
    { label: t('buy'), value: 'buy' },
  ];
  const toggleOnChange = (selectedTab: string) => {
    handlerStore.toggleSelectedTab(selectedTab as Tab);
  };

  // computed in results from calculations api call
  const isToggleDisabled =
    selectedTab === 'buy' && payOptForm.isLeaseIneligible;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.wrapper}>
        <ul className={styles.buttons}>
          {toggleOptions.map(option => {
            const thisButtonDisabled =
              option.value === 'lease' && isToggleDisabled;
            return (
              <li key={option.value}>
                <Cta
                  onClick={() => toggleOnChange(option.value)}
                  variant="withRedUnderline"
                  disabled={thisButtonDisabled}
                  value={option.value}
                  inactive={option.value !== selectedTab}
                  ariab-label={`Toggle ${option.value} payment options`}
                  aria-pressed={option.value === selectedTab}
                >
                  {option.label}
                </Cta>
              </li>
            );
          })}
          <button
            aria-label="lease and finance info"
            onClick={e => {
              setIsModalOpen(!isModalOpen);
              fireInfoCtaClick(e);
            }}
            className={styles.iconInfo}
          >
            <img src="/images/icons-x-small-info.png" alt="info icon" />
          </button>
        </ul>
      </div>
      <FinanceTypeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        ref={ref}
      ></FinanceTypeModal>
    </div>
  );
};

export default Toggle;
