export type TagType =
  | 'aa-link'
  | 'aa-pageload'
  | 'aa-subpageload'
  | 'aa-component-view'
  | 'km-pageload'
  | 'km-link'
  | 'km-link-1'
  | 'km-link-2'
  | 'km-pe-shown'
  | 'km-pe-start'
  | 'km-pe-complete'
  | 'km-cad-shown'
  | 'km-cad-start'
  | 'km-cad-complete'
  | 'km-offers-details'
  | 'km-offers-search'
  | 'ready';

type AnalyticsConstant = {
  TAGS: {
    [idx: string]: TagType;
  };
};

const AnalyticsConstants: AnalyticsConstant = {
  TAGS: {
    AALINK: 'aa-link',
    AAPAGELOAD: 'aa-pageload',
    AASUBPAGELOAD: 'aa-subpageload',
    AACOMPONENTVIEW: 'aa-component-view',
    KMPAGEPAGELOAD: 'km-pageload',
    KMLINK: 'km-link',
    KMLINK1: 'km-link-1',
    KMLINK2: 'km-link-2',
    KMPESHOWN: 'km-pe-shown',
    KMPESTART: 'km-pe-start',
    KMPECOMPLETE: 'km-pe-complete',
    KMCADSHOWN: 'km-cad-shown',
    KMCADSTART: 'km-cad-start',
    KMCADCOMPLETE: 'km-cad-complete',
  },
};

export default AnalyticsConstants;
