import { TrimData } from '../services/TrimsService';
import { EFCTrim } from '../models/EFC/EFCTrimsV2';

export const getMSRPPriceFromTrim = (trim: EFCTrim) =>
  Number(trim.price.base_msrp) + Number(trim.price.destination_fee);

// given trimData, compute lowest price for a given seriesYear
export const calcLowestMSRPForEachSeries = (vehicleResult: TrimData) => {
  // year => series => augmentedMSRP
  const lowestMsrpByYearSeries: {
    [year: string]: { [series: string]: number };
  } = {};
  for (const year of Object.keys(vehicleResult)) {
    lowestMsrpByYearSeries[year] = {};
    for (const series of Object.keys(vehicleResult[year])) {
      // get cheapest price for series across all trims
      const sortedByPrice = Object.values(vehicleResult[year][series])
        .map(getMSRPPriceFromTrim)
        .sort((a, b) => a - b);

      lowestMsrpByYearSeries[year][series] = sortedByPrice[0];
    }
  }
  return lowestMsrpByYearSeries;
};

export const sortModelsByMSRP = <T extends { price: { base_msrp: string } }>(
  models: T[]
) =>
  [...models].sort((a, b) =>
    Number(a.price.base_msrp) < Number(b.price.base_msrp)
      ? -1
      : Number(a.price.base_msrp) > Number(b.price.base_msrp)
      ? 1
      : 0
  );
