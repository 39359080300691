import { DealersFromService, Organization } from '../../models/VIEW/Dealers';

export const validateZipCodeInput = (zipcode: string) =>
  zipcode.length === 5 && zipcode.match(/\D/) === null;

const getRegionFromDealerResponse = (response: DealersFromService) => {
  const {
    specifiedOrganization,
  } = response.showDealerLocatorDataArea.dealerLocator[0].dealerLocatorDetail[0].dealerParty;
  return `${specifiedOrganization.divisionCode.value}0`;
};

const getStateCodeFromDealerResponse = (response: DealersFromService) => {
  const {
    specifiedOrganization,
  } = response.showDealerLocatorDataArea.dealerLocator[0].dealerLocatorDetail[0].dealerParty;
  return specifiedOrganization.postalAddress.stateOrProvinceCountrySubDivisionID
    .value;
};

const getTDACodeFromDealerResponse = (response: DealersFromService) => {
  return response.showDealerLocatorDataArea.dealerMetaData[0].dealerMetaDataDetail[0].organization.reduce(
    (tda: string, orgItem: Organization) => {
      if (orgItem.code.value === 'Association') {
        return orgItem.party[0].specifiedOrganization.companyCode.value;
      }
      return tda;
    },
    ''
  );
};

const getPricingAreaFromDealerResponse = (response: DealersFromService) =>
  response.showDealerLocatorDataArea.dealerMetaData[0].dealerMetaDataDetail[0].program.filter(
    item => item.code.value === 'PricingArea'
  )[0].description.value;

// extracts regions, stateCode, tdaCode and pricingArea from getDealers api response...
export const extractDataFromDealerResponse = (
  response: DealersFromService
) => ({
  regionCode: getRegionFromDealerResponse(response),
  stateCode: getStateCodeFromDealerResponse(response),
  tdaCode: getTDACodeFromDealerResponse(response),
  pricingArea: getPricingAreaFromDealerResponse(response),
});
