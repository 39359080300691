import React from 'react';

import styles from './ZipCode.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import Header from '../../components/Header';
import DevTools from '../../components/DevTools';
import Button from '../../modules/ZipCode/components/Button';
import TextInput from './components/TextInput';

import { useStore } from '../../stores';
import { useAnalyticsAAPageLoad } from '../../hooks/useAnalyticsAAPageLoad';
import { PAGE_TITLE_ZIPCODE } from '../../utils/Analytics';

const ZipCode: React.FC = () => {
  const { t } = useTranslation();
  const {
    zipCodeStore: {
      inputZipCode,
      isZipValid,
      zipCodeOnSubmit,
      setInputZipCode,
    },
    store: { setFirstPageLoaded, setIsFetching },
  } = useStore();

  React.useEffect(() => {
    setFirstPageLoaded();
    setIsFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = useAnalyticsAAPageLoad(PAGE_TITLE_ZIPCODE);

  const onSubmit = (event: React.MouseEvent) => {
    event.preventDefault();
    zipCodeOnSubmit();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedZip = event.currentTarget.value.replace(/[^\d]/gm, '');
    setInputZipCode(sanitizedZip);
  };

  return (
    <div className={styles.zipBody} ref={ref}>
      <DevTools />
      <Header />
      <main className={styles.zipContainer}>
        <form className={styles.form}>
          <div className={styles.formGroup}>
            <label className={styles.zipLabel}>
              <span className={styles.zipLabelText}>{t('enter_zip_code')}</span>
              <span>: </span>
              <TextInput
                required
                id="zipcodeInput"
                error={!isZipValid}
                describedBy="selectZipError"
                value={inputZipCode}
                onChange={onChange}
              />
            </label>{' '}
            <Button className={styles.zipBtn} onClick={onSubmit}>
              {t('btn_submit')}
            </Button>
            {!isZipValid && (
              <span id="selectZipError" className={styles['ui-state-error']}>
                {t('enter_valid_zip')}
              </span>
            )}
          </div>
        </form>
      </main>
    </div>
  );
};

export default observer(ZipCode);
