/*
 * This is part service part hook, that connects
 * the post message service and breakpoint threshold store
 * to a hook for use within the view
 */

import { useState, useEffect } from 'react';
import { useWindowSize } from '../hooks/useWindowSize';
import { useStore } from '../stores';
import BreakpointStore from '../stores/BreakpointStore';

const removeClass = (ele: HTMLElement, className: string) =>
  ele.classList.remove(className);
const addClass = (ele: HTMLElement, className: string) =>
  ele.classList.add(className);

const getWindowWidth = () => {
  return Math.max(
    window.document.documentElement.clientWidth,
    window.innerWidth || 0
  );
};

const DESKTOP_CLASS = 'bp-desktop';
const TABLET_CLASS = 'bp-tablet';
const MOBILE_CLASS = 'bp-mobile';

const getBreakpoints = ({
  isDesktop = false,
  isTablet = false,
  isMobile = false, // tslint:disable-line
}: {
  isDesktop?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
}) => ({
  isDesktop,
  isTablet,
  isMobile,
});

const computeBreakpoints = ({
  width,
  tablet,
  desktop,
}: {
  width?: number;
  tablet: number;
  desktop: number;
}) => {
  if (!width) {
    width = getWindowWidth();
  }
  if (width >= desktop) {
    return getBreakpoints({ isDesktop: true });
  } else if (width > tablet) {
    return getBreakpoints({ isTablet: true });
  } else {
    return getBreakpoints({ isMobile: true });
  }
};

export const isMobile = () => {
  const width = getWindowWidth();
  return width < BreakpointStore.tablet;
};

const addBodySwitcherClasses = ({
  isTablet,
  isDesktop,
}: {
  isTablet: boolean;
  isDesktop: boolean;
}) => {
  const bodyEl = document.querySelector('body') as HTMLBodyElement;
  if (isDesktop) {
    removeClass(bodyEl, TABLET_CLASS);
    removeClass(bodyEl, MOBILE_CLASS);
    addClass(bodyEl, DESKTOP_CLASS);
  } else if (isTablet) {
    removeClass(bodyEl, DESKTOP_CLASS);
    removeClass(bodyEl, 'bp-mobile');
    addClass(bodyEl, TABLET_CLASS);
  } else {
    removeClass(bodyEl, DESKTOP_CLASS);
    removeClass(bodyEl, TABLET_CLASS);
    addClass(bodyEl, 'bp-mobile');
  }
};

export const useBreakpoints = () => {
  const { width } = useWindowSize();
  const {
    breakpointStore: { tablet, desktop },
  } = useStore();

  const [breakpoints, setBreakpoints] = useState(
    computeBreakpoints({ width, tablet, desktop })
  );

  // listen to changes to window width or breakpoint thresholds and
  // update breakpoint state accordingly
  useEffect(() => {
    // either width or breakpoint thresholds have changed
    setBreakpoints(computeBreakpoints({ width, tablet, desktop }));
  }, [width, tablet, desktop]);

  return breakpoints;
};

// depending on width set body breakpoint switcher class
export const useSetBodyBreakpointClasses = () => {
  const breakpoints = useBreakpoints();

  // listen to change to window size and update breakpoint state accordingly
  // listen to change to breakpoints and update html body swither classes accordingly
  useEffect(() => {
    addBodySwitcherClasses(breakpoints);
  }, [breakpoints]);
};
