import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './DevTools.module.scss';

const DevTools = () => {
  const { i18n } = useTranslation();
  if (process.env.NODE_ENV === 'production') {
    return null;
  }
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className={styles.switcher}>
      <div>
        <button onClick={() => changeLanguage('en')}>en</button>
        <button onClick={() => changeLanguage('es')}>es</button>
      </div>
    </div>
  );
};

export default DevTools;
