import {
  isRateOffer,
  OfferForView,
  OfferType,
} from '../Factories/offerFactory';
import { isOfferSubvented, Subvention } from './subventionUtils';

// sonar qube paramter name change
const customerCashAndRateOffer = (offerA: OfferForView, offerB: OfferForView) =>
  offerA.offerType === OfferType.CUSTOMER_CASH && offerB.isRateOffer;

export const testCustomerCash = (offer1: OfferForView, offer2: OfferForView) =>
  (customerCashAndRateOffer(offer1, offer2) && isOfferSubvented(offer2)) ||
  (customerCashAndRateOffer(offer2, offer1) && isOfferSubvented(offer1));

export const testCompatibility = (
  offer1: OfferForView,
  offer2: OfferForView
) => {
  // Customer cash and non subvented rate ignores compatibility rules
  if (
    customerCashAndRateOffer(offer1, offer2) ||
    customerCashAndRateOffer(offer2, offer1)
  ) {
    return false;
  }

  return (
    (offer1.compatibility &&
      !offer1.compatibility.includes(offer2.offerType)) ||
    (offer2.compatibility &&
      !offer2.compatibility.includes(offer1.offerType)) ||
    (offer1.offerType !== 'Lease' &&
      offer2.offerType === 'Complimentary First Payment') ||
    (offer1.offerType === 'Complimentary First Payment' &&
      offer2.offerType !== 'Lease')
  );
};

export const testMilitaryCollege = (
  offer1: OfferForView,
  offer2: OfferForView
) =>
  (offer1.offerType === OfferType.MILITARY &&
    offer2.offerType === OfferType.COLLEGE) ||
  (offer1.offerType === OfferType.COLLEGE &&
    offer2.offerType === OfferType.MILITARY);

const tfsContractListAndSubvented = (
  offerA: OfferForView,
  offerB: OfferForView
) =>
  isRateOffer(offerA) &&
  offerB.tfsContractList &&
  offerB.tfsContractList.length &&
  offerA.isSubvented !== offerB.isSubvented && // if offer subventions don't match
  offerA.isSubvented !== Subvention.BOTH && // WFMB-198 and both offers aren't compatible with subvented and non subvented
  offerB.isSubvented !== Subvention.BOTH;

export const testContracts = (offer1: OfferForView, offer2: OfferForView) => {
  // Customer cash and non subvented rate ignores compatibility rules
  if (
    customerCashAndRateOffer(offer1, offer2) ||
    customerCashAndRateOffer(offer2, offer1)
  ) {
    return false;
  }

  if (
    tfsContractListAndSubvented(offer1, offer2) ||
    tfsContractListAndSubvented(offer2, offer1)
  ) {
    return true;
  }

  if (
    offer1.tfsContractList &&
    offer2.tfsContractList &&
    offer1.isSubvented !== offer2.isSubvented &&
    offer1.isSubvented !== Subvention.BOTH &&
    offer2.isSubvented !== Subvention.BOTH
  ) {
    return true;
  }
  return false;
};
