import React, { useEffect, useState } from 'react';
import { Router, Route } from 'react-router-dom';
import './i18n'; // init i18n setup

import { StoreProvider, history } from './stores';
import GlobalStore from './stores/GlobalStore';
import { useSetBodyBreakpointClasses } from './hooks/useBreakpoints';

import Modal from './components/LoadingModal';
import StatusBar from './components/StatusBar';
import ZipCode from './modules/ZipCode';
import Estimator from './modules/Estimator';
import VehicleSelect from './modules/VehicleSelect';
import ZipCodeParamRedirect from './modules/ZipCodeRedirect';
import * as PostMessageService from './services/PostMessageService';

import Script from 'react-load-script';

const analyticsScript = process.env.REACT_APP_ANALYTICS_SCRIPT;

PostMessageService.initialize();

declare global {
  interface Window {
    fireTag: (event: string, data: string) => void;
  }
}

const AppWideHooksInit: React.FC = ({ children }) => {
  useSetBodyBreakpointClasses();
  useEffect(() => {
    GlobalStore.setGlobalParams();
  }, []);
  return <>{children}</>;
};

const App: React.FC = () => {
  const [isScriptsLoaded, setIsScriptsLoaded] = useState(false);
  const handleScriptCallback = () => setIsScriptsLoaded(true);

  return (
    <StoreProvider>
      <Script
        url={analyticsScript}
        onLoad={() => handleScriptCallback()}
        onError={() => handleScriptCallback()}
      />
      {isScriptsLoaded && (
        <AppWideHooksInit>
          <Router history={history}>
            <Route path="/" exact component={ZipCode} />
            <ZipCodeParamRedirect path="/vehicleSelect">
              <VehicleSelect />
            </ZipCodeParamRedirect>
            <ZipCodeParamRedirect path="/estimator">
              <Estimator />
            </ZipCodeParamRedirect>
          </Router>
          <Modal />
          <StatusBar />
        </AppWideHooksInit>
      )}
    </StoreProvider>
  );
};

export default App;
