import { observable, decorate } from 'mobx';

const BreakpointStore = {
  tablet: 768,
  desktop: 960,
};

decorate(BreakpointStore, {
  tablet: observable,
  desktop: observable,
});

export default BreakpointStore;
