import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { useStore } from '../../../../stores';
import ModalBase from 'components/ModalBase';
import { sendHeight } from 'services/PostMessageService';
import Cta from 'components/Cta';
import { formatNumber, formatNumberWithCurrency } from 'utils/numbers';
import Button from 'modules/ZipCode/components/Button';
import PriceDetailsList from './components/PriceDetailsList';
import { GradeView } from '../../../../models/VIEW/EstimatorStore';
import { MediaType } from 'models/VIEW/VehicleDetails';
import { onEnterPress } from 'utils/onEnterPress';
import { getQueryParams } from 'utils/history';
import styles from './style.module.scss';

const PriceDetailsModal: React.FC = () => {
  const { t } = useTranslation();
  const [vin, setVin] = useState<string>('');

  const {
    modalStore: {
      displayPriceDetailsModal,
      closePriceDetailsModal,
      openContactDealerModal,
      openDisclaimerModal,
    },
    estimatorStore: {
      payOptForm,
      selectedTab,
      selectedVehicle,
      selectedVehicleDetails,
      selectedYearSeries,
    },
  } = useStore();

  const {
    dueAtSigning,
    payment,
    mileage,
    terms,
    rate,
    downPaymentSum,
    markup,
    selectedOffers,
  } = payOptForm;

  useEffect(() => {
    sendHeight();
  });

  const isBuyTab = selectedTab === 'buy';

  const contactDealerOnclick = () => {
    closePriceDetailsModal();
    openContactDealerModal(null, false);
  };

  const openDisclaimersModal = (disclaimerId: string) => {
    closePriceDetailsModal();
    openDisclaimerModal(disclaimerId);
  };

  const carjellyimage =
    selectedVehicleDetails.media &&
    selectedVehicleDetails.media.find(
      media => Number(MediaType[media.type]) === MediaType.carjellyimage
    );

  useEffect(() => {
    const { vin } = getQueryParams();
    setVin(vin as string);
  }, []);

  return (
    <ModalBase isOpen={displayPriceDetailsModal} darkOverlay>
      <div className={styles.modalWrapper}>
        <div className={styles.headerWrapper}>
          <div>
            <div className={styles.title}>
              {isBuyTab ? (
                <>{t('price_details.finance_price_details_title')}</>
              ) : (
                <>{t('price_details.lease_price_details_title')}</>
              )}
            </div>
            <div className={styles.vin}>VIN {vin}</div>
            <div className={styles.subtitle}>
              {selectedYearSeries.title}{' '}
              {
                (
                  selectedYearSeries.tabManager[selectedTab]
                    .selectedGrade as GradeView
                ).title
              }
            </div>
            {/* Print estimate btn */}
            <div className={styles.printWrapper}>
              <img src="/images/icon-print.png" alt="pdf icon" />
              <Cta
                variant="withRedUnderline"
                className={styles.printBtn}
                onClick={() => window.print()}
                onKeyPress={e => {
                  onEnterPress(e, () => window.print());
                }}
              >
                Save As PDF
              </Cta>
            </div>
          </div>
          <div>
            <img
              alt={selectedVehicle.model}
              src={carjellyimage?.href}
              onLoad={sendHeight}
            ></img>
          </div>
          <div className={styles.closeModal}>
            <button
              className={styles.closeButton}
              onClick={closePriceDetailsModal}
              onKeyPress={e => {
                onEnterPress(e, () => closePriceDetailsModal());
              }}
            >
              &#215;
            </button>
          </div>
        </div>
        <div className={styles.bodyWrapper}>
          <div
            className={cx(styles.paymentDetails, {
              [styles.leasePaymentDetails]: !isBuyTab,
            })}
          >
            <div>
              {/* Monthly payment */}
              <div>
                ${typeof payment === 'number' ? String(payment) : '-'}
                <span className={styles.sub}>/{t('month_abbreviation')}</span>
              </div>
              {/*APR or mileage*/}
              <div>
                {isBuyTab && `${formatNumber((rate ?? 0) + (markup ?? 0))}%`}
                {isBuyTab && (
                  <span className={styles.sub}>
                    APR{' '}
                    <button
                      onClick={() => openDisclaimersModal('4')}
                      onKeyPress={e => {
                        onEnterPress(e, () => {
                          openDisclaimersModal('4');
                        });
                      }}
                    >
                      4
                    </button>
                  </span>
                )}
              </div>
            </div>
            <div className={styles.paymentDetailsItem}>
              {/*Terms*/}
              <div>
                {terms}
                <span className={styles.sub}>
                  &nbsp; mos{' '}
                  <button
                    onClick={() => openDisclaimersModal('3')}
                    onKeyPress={e => {
                      onEnterPress(e, () => {
                        openDisclaimersModal('3');
                      });
                    }}
                  >
                    3
                  </button>
                </span>
              </div>
              {isBuyTab ? (
                <>
                  <div className={styles.divider}></div>
                  <span className={styles.downPaymentWrapper}>
                    {isBuyTab
                      ? formatNumberWithCurrency(downPaymentSum)
                      : formatNumberWithCurrency(dueAtSigning)}
                    <p>{t('down_payment')} required</p>
                  </span>
                </>
              ) : (
                <div>
                  {!isBuyTab && `$${dueAtSigning}`}
                  {!isBuyTab && (
                    <span className={styles.sub}>
                      <span className={styles.dueAtSigningWrapper}>
                        &nbsp;&nbsp;{t('due_at_signing')}
                      </span>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Mileage */}
          <div className={styles.mileageAndDepositWrapper}>
            {!isBuyTab && (
              <>
                <div>
                  {`${mileage ? mileage / 1000 : 0}K ${t('annual_mileage')}`}
                  <button
                    onClick={() => openDisclaimersModal('6')}
                    onKeyPress={e => {
                      onEnterPress(e, () => {
                        openDisclaimersModal('6');
                      });
                    }}
                  >
                    6
                  </button>
                </div>
                <div className={styles.divider}></div>
                <div>{`${formatNumberWithCurrency(
                  payOptForm?.securityDeposit
                )} security deposit required`}</div>
              </>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <Button onClick={contactDealerOnclick} className={styles.btn}>
              {t('contact_dealer')}
            </Button>
            <div className={styles.disclaimerWrapper}>
              {selectedOffers.filter(
                offer => offer.isAdvertised && offer.selected
              ).length === 1 && (
                <>
                  Offer expires {selectedOffers[0].endDate}. Does not include
                  title, taxes and fees.
                  <br />
                </>
              )}
            </div>
          </div>
          <PriceDetailsList openDisclaimersModal={openDisclaimersModal} />
        </div>
      </div>
    </ModalBase>
  );
};

export default observer(PriceDetailsModal);
