import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../stores';

import styles from './LoadingModal.module.scss';

const LoadingModal: React.FC = () => {
  const {
    store: { isFetching },
  } = useStore();
  const { t } = useTranslation();
  return (
    <>
      {isFetching && (
        <>
          <div className={styles.overlay} />
          <div className={styles.modal}>
            <p>{t('loading')}</p>
          </div>
        </>
      )}
    </>
  );
};

export default observer(LoadingModal);
