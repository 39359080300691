import {
  EFCSeriesSeriesData,
  IEFCSeriesLatestYearData,
} from '../models/EFC/EFCSeriesV1';

// gets most recent year for each series
export const getSeriesYear = (
  data: EFCSeriesSeriesData[]
): IEFCSeriesLatestYearData => {
  const seriesLatestYear: IEFCSeriesLatestYearData = {} as IEFCSeriesLatestYearData;

  for (const seriesYear of data) {
    for (const series of seriesYear.series_list) {
      if (
        !seriesLatestYear[series.code] ||
        seriesLatestYear[series.code].year < Number(seriesYear.year)
      ) {
        seriesLatestYear[series.code] = {
          ...series,
          year: Number(seriesYear.year),
        };
      }
    }
  }

  return seriesLatestYear;
};
