/*
  This file use the calculations api to figure out
  which group of offers result in the lowest payment
*/
import {
  Tab,
  InitObj,
  BuyOfferTab,
  LeaseOfferTab,
} from '../../../../models/VIEW/ViewOffersTab';
import { OfferType } from '../../Factories/offerFactory';
import { PEMSCalculateRequestData } from '../../../../services/WebServicesAPI';
import {
  Payments,
  isPayment,
  PEMCalculateResponse,
  PaymentsErrorResponse,
} from '../../../../models/PEMS/PEMSCalculate';
import {
  Grades,
  GradeVehicleModel,
} from '../../../../models/VIEW/EstimatorStore';
import {
  getOffersByTierTermKey,
  selectBaseOffer,
} from '../../Utils/offersFactoryUtils';
import {
  buildRequest,
  calculateCosts,
  getPaymentsFromPems,
} from './commonCalsApi';
import { deselectAndDisableIncompatibleOffers } from '../../Factories/offersFactory';
import EstimatorStore from 'stores/EstimatorPage';
import { getQueryParams } from 'utils/history';

// Set of trims/tiers/terms for which we've already selected the lowest terms.
// keys in format (buy | lease)-trim-tier-term
const tierTermsLowestPaymentsCalculated = new Set();
export const createSeenKeyFromModel = (model: GradeVehicleModel, type: Tab) =>
  `${type}-${model.code}-${
    model.peOffers[type].tier === 0 ? '1+' : model.peOffers[type].tier
  }-${model.peOffers[type].terms}`;

// for sonar qube issues
export const isLowestPaymentCalculated = (key: string) =>
  tierTermsLowestPaymentsCalculated.has(key);

const updateTierTermsLowestPaymentsCalculated = (
  requests: Array<{
    tier: string;
    term: string | number;
    trimCode: string;
    type: Tab;
  }>
) => {
  for (const request of requests) {
    const { tier, term, trimCode, type } = request;
    tierTermsLowestPaymentsCalculated.add(
      `${type}-${trimCode}-${tier}-${term}`
    );
  }
};

export const findLandingModelCode = async ({
  year,
  series,
  grades,
  tabParam,
  trimParam,
  msrpParam,
  regionCode,
  pricingArea,
  initialOfferId,
  preselectedOffer,
  state,
}: {
  year: string;
  grades: Grades;
  series: string;
  tabParam?: string;
  trimParam?: any;
  msrpParam?: string;
  regionCode: string;
  pricingArea: string;
  preselectedOffer?: InitObj;
  initialOfferId?: string;
  state: string;
}) => {
  const [requests, peOffers] = buildRequestsForAllModels({
    year,
    series,
    grades,
    regionCode,
    pricingArea,
    preselectedOffer,
    state,
  });

  const result = await getPaymentsFromPems({
    data: requests,
    regionCode,
    peOffers,
  });

  // update seen set
  // using result data as we don't want to set the unused APR terms
  // which we will need to calculate again if.
  updateTierTermsLowestPaymentsCalculated(result.payments.filter(isPayment));

  // find lowest target payment and return model code and tab
  let tab = result.lowestPayment.type;
  let modelCode = result.lowestPayment.trimCode;

  // use offers from payment results to set details for each model tab
  setPaymentDetails(grades, result, msrpParam);

  // if offerId exists go to this model code
  // else if model code is coming from params, just find the tab type
  if (preselectedOffer) {
    tab = preselectedOffer.tab;
    modelCode = preselectedOffer.offer.modelCode || trimParam;
  } else if (trimParam) {
    modelCode = trimParam;
    const tempTab = selectLowestTab(grades, trimParam);
    if (tempTab) {
      tab = tempTab;
    }
  }

  // OATM-1508: added ability to pass finance/lease as parameter
  if (initialOfferId === undefined && tabParam !== undefined) {
    if (tabParam === 'finance') {
      tab = 'buy';
    } else if (tabParam === 'lease') {
      tab = 'lease';
    }
  }

  return {
    modelCode,
    tab,
  };
};

const selectLowestTab = (grades: Grades, trimParam: string) => {
  let tab;
  const model = grades
    .reduce(
      (acc, grade) => [...acc, ...grade.models],
      [] as GradeVehicleModel[]
    )
    .find(({ code }) => code === trimParam);
  if (model) {
    tab =
      Number(model.peOffers.buy.targetPayment) <=
      Number(model.peOffers.lease.targetPayment)
        ? 'buy'
        : 'lease';
  }
  return tab;
};

// when user changes tier term, we need to get offer combo
// for tab with lowest payment. Here, there is no need to
// choose between tabs or find the model code with lowest
// payment.
export const calcLowestPaymentForNewTierTerm = async ({
  year,
  type,
  series,
  regionCode,
  pricingArea,
  peOffer,
  model,
  state,
}: {
  year: string;
  type: Tab;
  model: GradeVehicleModel;
  series: string;
  peOffer: BuyOfferTab | LeaseOfferTab;
  pricingArea: string;
  regionCode: string;
  state: string;
}) => {
  const request = [
    buildRequest({
      year,
      terms: peOffer.terms,
      series,
      peOffer,
      baseMsrp: model.baseMsrp,
      modelCode: model.code,
      customMsrp: model.customMsrp,
      pricingArea,
      isLowestPaymentCalculation: true,
      sendDownPayment:
        type === 'lease' && !EstimatorStore.isDirty ? false : true,
      state,
    }),
  ];

  const result = await getPaymentsFromPems({
    regionCode,
    data: request,
    peOffers: [peOffer],
  });

  // update seen set
  updateTierTermsLowestPaymentsCalculated(request);

  // use offers from lowestPayment result and preselects them for each model
  updateTabFromPaymentResult(
    model,
    model.peOffers[type],
    result.payments[0],
    model.customMsrp
  );

  return result;
};

export const setPaymentDetails = (
  grades: Grades,
  result: PEMCalculateResponse,
  customMsrp?: string
) => {
  grades.forEach(grade => {
    grade.models.forEach(model => {
      const buyPayment = result.payments.find(
        payment =>
          isPayment(payment) &&
          payment.trimCode === model.code &&
          payment.type === 'buy'
      );

      const leaseFiltered = result.payments.filter(
        payment =>
          isPayment(payment) &&
          payment.term === 39 &&
          payment.trimCode === model.code &&
          payment.type === 'lease'
      );

      let leasePayment;

      if (leaseFiltered.length !== 0) {
        leasePayment = leaseFiltered[0];
      } else {
        leasePayment = result.payments.find(
          payment =>
            isPayment(payment) &&
            payment.trimCode === model.code &&
            payment.type === 'lease'
        );
      }

      updateTabFromPaymentResult(model, model.peOffers.buy, buyPayment);
      updateTabFromPaymentResult(
        model,
        model.peOffers.lease,
        leasePayment,
        customMsrp
      );
    });
  });
};

const updateTabFromPaymentResult = (
  model: GradeVehicleModel,
  tab: LeaseOfferTab | BuyOfferTab,
  payment?: Payments | PaymentsErrorResponse,
  customMsrp?: string
) => {
  if (payment && isPayment(payment)) {
    selectSelectedOffersFromAPI(model, payment, tab, customMsrp);
    calculateCosts(model, tab, true);
    deselectAndDisableIncompatibleOffers(tab.offers);
  }
};

/**
 * Offers returned from calc api should be selected,
 * unless they have a non matching model code
 */
const selectSelectedOffersFromAPI = (
  model: GradeVehicleModel,
  payment: Payments,
  tab: LeaseOfferTab | BuyOfferTab,
  isCustomMsrp?: string
) => {
  // find offers from payment response and use
  const tier = payment.tier;
  const term = payment.term;

  // add offer Id to no advertised rate offers
  payment.offers.forEach(offer => {
    if (!offer.advertised) {
      offer.offerId = `${offer.rate}-${offer.tier}-${offer.term}`;
    }
  });

  const selectedOffersPEMSSet = new Set(
    payment.offers.map(offer => offer.offerId)
  );
  const modelOffers = [
    ...getOffersByTierTermKey(
      model.peOffers[payment.type].tierTermsOffersMap,
      `${tier} ${term}`
    ),
    model.peOffers[payment.type].tierTermsNonAdMap[`${tier} ${term}`], // can be undefined
  ].filter(offer => !!offer);

  modelOffers.forEach(offer => {
    const tdaOfferId =
      offer.tdaOfferId ?? `${offer.rate}-${offer.tier}-${offer.terms}`;
    if (
      selectedOffersPEMSSet.has(tdaOfferId) &&
      offer.modelCode === offer.selectedModelCode &&
      (offer.offerType !== OfferType.LEASE || isCustomMsrp === undefined) &&
      (!EstimatorStore.isDirty || offer.offerType !== OfferType.LEASE)
    ) {
      offer.selected = true; // deselect lease offers if msrp param present
    }
  });

  tab.selectedOffers = modelOffers.filter(({ selected }) => selected);
  if (tab.selectedOffers[0]?.offerType === 'Lease') {
    selectBaseOffer(tab.selectedOffers[0], tab);
  }
};

const buildRequestsForAllModels = ({
  year,
  series,
  grades,
  regionCode,
  pricingArea,
  state,
}: {
  year: string;
  grades: Grades;
  series: string;
  regionCode: string;
  pricingArea: string;
  preselectedOffer?: InitObj;
  state: string;
}) => {
  const requests: PEMSCalculateRequestData[] = [];
  const tabsOrderedByRequest: Array<BuyOfferTab | LeaseOfferTab> = [];
  grades.forEach(grade => {
    grade.models.forEach(model => {
      const buildRequestParams = (
        peOffer: BuyOfferTab | LeaseOfferTab,
        terms?: string
      ) => ({
        year,
        series,
        peOffer,
        modelCode: model.code,
        baseMsrp: model.baseMsrp,
        customMsrp: model.isCustomMsrp ? model.customMsrp : undefined,
        regionCode,
        pricingArea,
        terms: terms ?? peOffer.terms,
        isLowestPaymentCalculation: true,
        state,
      });

      const requestBuy60 = buildRequest(
        buildRequestParams(model.peOffers.buy, '60')
      );

      const requestBuy72 = buildRequest(
        buildRequestParams(model.peOffers.buy, '72')
      );

      const requestLease36 = buildRequest(
        buildRequestParams(model.peOffers.lease)
      );

      // if there are offers with 39 months
      const isTermFound = model.peOffers.lease.availableTerms?.findIndex(
        term => term === 39
      );

      let requestLease39;

      if (isTermFound !== -1) {
        requestLease39 = buildRequest(
          buildRequestParams(model.peOffers.lease, '39')
        );
      }
      // required to order tabs by request. If
      // one errors, error doesn't contain identifiying
      // details, so index is identifier of which tab to update
      // with error message
      tabsOrderedByRequest.push(model.peOffers.buy);
      tabsOrderedByRequest.push(model.peOffers.buy);
      tabsOrderedByRequest.push(model.peOffers.lease);

      requests.push(requestBuy60);
      requests.push(requestBuy72);
      requests.push(requestLease36);

      const queryParams = getQueryParams();
      const { trim } = queryParams;

      // if trim is specified inside url, send only 39 months offers that belongs to that trim

      if (
        (trim && trim === requestLease39?.offers[0]?.modelCode) ||
        (!trim && requestLease39 !== undefined)
      ) {
        tabsOrderedByRequest.push(model.peOffers.lease);
        requests.push(requestLease39);
      }
    });
  });
  return [requests, tabsOrderedByRequest] as const;
};
