import { getQueryParams } from '../history';
import { buildAnalyticsBlocksForModal } from './estimatorAnalytics';
import * as AnalyticsService from '.';

const extendGlobalBlockOfferDetails = (block: any, zip?: string) => {
  block.zipcode = zip;
  block.content_section = AnalyticsService.PAGE_TITLE_OFFER_MODAL;
  return block;
};

export const onOfferDetailsload = () => {
  const { zip } = getQueryParams();
  const { vehicleBlock } = buildAnalyticsBlocksForModal();
  let block = AnalyticsService.getToolBlock(vehicleBlock);
  block = extendGlobalBlockOfferDetails(block, zip as string);
  AnalyticsService.fireAAPageLoad(block);
};
