export type VehicleDetailsFromService = {
  options: Array<Option>;
  media: Array<MediaDetails>;
  price: VehiclePrice;
};

export type Option = {
  optionCd: string;
  marketingName: string;
  msrp: number;
};

export type MediaDetails = {
  href: string;
  type: MediaType;
};

export enum MediaType {
  carjellyimage,
  exterior,
  interior,
}

export type VehiclePrice = {
  baseMsrp: number;
  totalMsrp: number;
  optTotalMsrp: number;
  dph: number;
};

export type AccessoryDisclaimers = {
  id: string;
  code: string;
  description: string;
};
