import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores';

import styles from './styles.module.scss';

const PositiveEquityNotification: React.FC = () => {
  const { t } = useTranslation();

  const {
    modalStore: { openContactDealerModal },
  } = useStore();

  return (
    <div className={styles.notificationWrapper}>
      <div className={styles.title}>
        <img src="/images/blue-info-icon.png" alt="icon info"></img>
        {t('positive_notification_title')}
      </div>
      <div className={styles.text}>
        {t('positive_notification_text_one')}{' '}
        <button
          onClick={() => {
            openContactDealerModal(null, false);
          }}
        >
          {t('contact_us')}
        </button>{' '}
        {t('positive_notification_text_two')}
      </div>
    </div>
  );
};

export default observer(PositiveEquityNotification);
