import { useState, useEffect } from 'react';

const isClient = typeof window === 'object';

function getSize() {
  return {
    width: isClient
      ? Math.max(
          window.document.documentElement.clientWidth,
          window.innerWidth || 0
        )
      : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
}

// https://usehooks.com/useWindowSize/
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize);

  // add onresize handlers
  useEffect(() => {
    if (!isClient) {
      return () => {};
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};
