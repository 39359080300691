import React from 'react';
import cx from 'classnames';

import styles from './Heading.module.scss';

interface HeadingProps {
  text?: string;
  small?: boolean;
}

const Heading: React.FC<HeadingProps> = ({ text, small = false }) => {
  return (
    <section className={styles.heading}>
      <h1 className={cx(styles.text, { [styles.smallText]: small })}>{text}</h1>
    </section>
  );
};

export default Heading;
