import { ColorData } from '../services/ColorsService';
import { EFCModelMap } from '../constants/VehicleConstants';

export const getImageForSeries = ({
  year,
  series,
  colorsDataResult,
}: {
  year: string;
  series: string;
  colorsDataResult: ColorData;
}) => {
  const vehicleColorToMatch = EFCModelMap[series]?.vehicleColor;
  // return first trim with that a matching color code with EFCModelMap
  const years: string[] = year.split(',').reverse();
  for (const yearSplit of years) {
    for (const trim of Object.keys(
      colorsDataResult?.[yearSplit]?.[series] ?? {}
    )) {
      if (
        vehicleColorToMatch &&
        vehicleColorToMatch in colorsDataResult[yearSplit][series][trim]
      ) {
        return `${process.env.REACT_APP_EFC_BASE_CAR_JELLY_URL}${colorsDataResult[yearSplit][series][trim][vehicleColorToMatch].car_jelly_image}`;
      }
    }
  }

  // If here, that means we haven't found a matching color code. Pick a random one.
  for (const year of years) {
    if (colorsDataResult[year][series]) {
      const firstTrim = Object.values(colorsDataResult[year][series])[0];
      return `${process.env.REACT_APP_EFC_BASE_CAR_JELLY_URL}${
        Object.values(firstTrim)[0]?.car_jelly_image
      }`;
    }
  }
  return '';
};
