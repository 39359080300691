import i18n from 'i18next';

import { PEMCalculateResponse } from '../../../../models/PEMS/PEMSCalculate';
import {
  Grades,
  GradeVehicleModel,
} from '../../../../models/VIEW/EstimatorStore';
import GlobalStore from '../../../GlobalStore';
import {
  Tab,
  BuyOfferTab,
  LeaseOfferTab,
} from '../../../../models/VIEW/ViewOffersTab';
import {
  createSeenKeyFromModel,
  isLowestPaymentCalculated,
  calcLowestPaymentForNewTierTerm,
} from './lowestPaymentCalculations';
import {
  buildRequest,
  calculateCosts,
  getPaymentsFromPems,
} from './commonCalsApi';
import { deselectAndDisableIncompatibleOffers } from '../../Factories/offersFactory';

export const calculateCostsForAllModelsInGrades = (grades: Grades) => {
  grades.forEach(grade => {
    grade.models.forEach(model => {
      calculateCosts(model, model.peOffers.buy, false);
      calculateCosts(model, model.peOffers.lease, false);
    });
  });
};

const calcPaymentForSpecificSelection = ({
  year,
  type,
  model,
  series,
  regionCode,
  pricingArea,
  state,
}: {
  year: string;
  type: Tab;
  model: GradeVehicleModel;
  series: string;
  regionCode: string;
  pricingArea: string;
  state: string;
}) => {
  let peOffers = { buy: {}, lease: {} };
  if (model.peOffers) {
    peOffers = model.peOffers;
    // also preselects any necessary offers (advertised Rate, non adv Rate, cash, etc...)
    calculateCosts(model, model.peOffers[type], true);
  }
  const peOffer = peOffers[type] as BuyOfferTab | LeaseOfferTab;

  return getPaymentsFromPems({
    regionCode,
    data: [
      buildRequest({
        year,
        terms: peOffer.terms,
        series,
        peOffer,
        baseMsrp: model.baseMsrp,
        modelCode: model.code,
        customMsrp: model.customMsrp,
        pricingArea,
        state,
      }),
    ],
    peOffers: [model.peOffers[type]],
  });
};

export const updateValues = async ({
  year,
  type,
  model,
  series,
  regionCode,
  pricingArea,
  state,
}: {
  year: string;
  type: Tab;
  model: GradeVehicleModel;
  series: string;
  regionCode: string;
  pricingArea: string;
  state: string;
}) => {
  let response: PEMCalculateResponse;
  try {
    const lowestCalcKey = createSeenKeyFromModel(model, type);
    if (!isLowestPaymentCalculated(lowestCalcKey)) {
      response = await calcLowestPaymentForNewTierTerm({
        year,
        model,
        series,
        peOffer: model.peOffers[type],
        regionCode,
        pricingArea,
        type,
        state,
      });
    } else {
      response = await calcPaymentForSpecificSelection({
        year,
        type,
        model,
        series,
        regionCode,
        pricingArea,
        state,
      });
    }

    deselectAndDisableIncompatibleOffers(model.peOffers[type].offers);

    // Unsure in what case this occures.
    // I can't see this code anywhere
    if (response.errorMessage) {
      const typeLabel =
        type === 'lease' ? `[${i18n.t('lease')}] ` : `[${i18n.t('buy')}] `;
      const errMsg = response.errorMessage;
      // 2004 = residual value doesn't exist
      GlobalStore.showStatusBar(
        response.errorCode === 2004
          ? i18n.t('lease_ineligible')
          : `${typeLabel}${errMsg}`
      );
    }
  } catch (err) {
    if ('isAxiosError' in err) {
      GlobalStore.serviceError(err);
    } else {
      GlobalStore.showStatusBar(err.message);
    }
  }
  return {
    targetPayment: model.peOffers[type].targetPayment,
    dueAtSigning: model.peOffers[type].dueAtSigning,
    rate: model.peOffers[type].rate,
  };
};
