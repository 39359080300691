import React, { useEffect } from 'react';

export const useScrollTo = (ref: React.RefObject<HTMLElement>, id: string) => {
  useEffect(() => {
    const element = document.getElementById(id);
    if (ref.current && element) {
      ref.current.scrollTop = element.offsetTop;
    }
  }, [ref, id]);
};
