import { getEFCSeriesV1, getMycoFile } from './WebServicesAPI';
import {
  EFCSeriesV1Response,
  EFCSeriesSeriesData,
  EFCSeriesV1SeriesList,
} from '../models/EFC/EFCSeriesV1';
import { InactiveSeries, EFCModelMap } from '../constants/VehicleConstants';
import { memoizeLast } from '../utils/memo';
import { get3YearSpread } from '../utils/date';
import { MYCOModels } from '../models/MYCOModels';

// PE supports model years based on current, previous and next logic.
// Sending a year for which there is no data to api
// is simply ignored by api as long as there are
// other valid years in request.
const getYearsForEFCSeriesCall = () => {
  const [previousYear, year, nextYear] = get3YearSpread();
  return `${previousYear},${year},${nextYear}`;
};

// also checking we have a color mapping
const excludeInActiveSeries = (
  series: EFCSeriesSeriesData,
  mycoModels: MYCOModels
) =>
  Object.assign({}, series, {
    series_list: series.series_list.filter(
      _series =>
        !InactiveSeries.includes(_series.code) &&
        EFCModelMap[_series.code] &&
        filterMyco(_series, series, mycoModels)
    ),
  });

const refactorSeries = (data: EFCSeriesSeriesData[]) => {
  data.forEach((element: EFCSeriesSeriesData) => {
    element.series_list.forEach((series: EFCSeriesV1SeriesList) => {
      if (series.code === 'tacomahybrid') {
        series.title = 'Tacoma i-FORCE MAX';
      }
    });
  });
};

const filterSeries = async (series: EFCSeriesSeriesData[]) => {
  const mycoModels = await getMycoFile();

  refactorSeries(series);

  return series.map(iseries => excludeInActiveSeries(iseries, mycoModels));
};

const filterMyco = (
  _seriesList: EFCSeriesV1SeriesList,
  series: EFCSeriesSeriesData,
  mycoModels: MYCOModels
) => {
  let seriesMatch = false;

  mycoModels.yearSeriesModelsNameList.forEach(model => {
    if (
      series.year === model.year &&
      (_seriesList.code === model.seriesCode ||
        (_seriesList.code === 'chr' && model.seriesCode === 'c-hr'))
    ) {
      seriesMatch = true;
    }
  });

  return seriesMatch;
};

const getSeriesInner = async (region: string) => {
  const years = getYearsForEFCSeriesCall();
  const response: { data: EFCSeriesV1Response } = await getEFCSeriesV1({
    region,
    years,
  });

  return filterSeries(response.data.series_data);
};

// memoized function that prevents recalling base series api
// if already called for a specific region
export const getSeries = memoizeLast(getSeriesInner);
