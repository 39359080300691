import React, { useEffect } from 'react';

const useOutsideAlerter = (ref: React.RefObject<any>, callback: () => void) => {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: React.MouseEvent) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener(
      'mousedown',
      (handleClickOutside as unknown) as EventListener
    );
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(
        'mousedown',
        (handleClickOutside as unknown) as EventListener
      );
    };
  });
};

export default useOutsideAlerter;
