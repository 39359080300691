import React, { createContext, useContext } from 'react';
import { useLocalStore } from 'mobx-react-lite';

import { createHashHistory } from 'history';
import RootStore from './GlobalStore';
import ZipCodeStore from './ZipCodeStore';
import EstimatorPageStore from './EstimatorPage';
import BreakpointStore from './BreakpointStore';
import ModalStore from './ModalStore';

export const history = createHashHistory();

export type StoreContextType = {
  store: typeof RootStore;
  estimatorStore: typeof EstimatorPageStore;
  zipCodeStore: typeof ZipCodeStore;
  breakpointStore: typeof BreakpointStore;
  modalStore: typeof ModalStore;
};

export const StoreContext = createContext<StoreContextType | null>(null);

export const StoreProvider: React.FC = ({ children }) => {
  const store = useLocalStore(() => ({
    store: RootStore,
    zipCodeStore: ZipCodeStore,
    estimatorStore: EstimatorPageStore,
    breakpointStore: BreakpointStore,
    modalStore: ModalStore,
  }));

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('StoreProvider has not been injected');
  }
  return store;
};
