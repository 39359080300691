import { arrayElementsEqual } from './array';

export const memoizeLast = (func: (idx?: any) => any) => {
  let lastArgs: any[] = [null];
  let lastRes: any = null;
  return (...args: any) => {
    if (arrayElementsEqual(args, lastArgs)) {
      return lastRes;
    } else {
      lastRes = func(...args);
      lastArgs = args;
      return lastRes;
    }
  };
};
