import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getQueryParams } from 'utils/history';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useStore } from 'stores';
import { onEnterPress } from 'utils/onEnterPress';
import styles from './styles.module.scss';

const Subheader: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const {
    modalStore: { openDisclaimerModal },
  } = useStore();

  const [isDAP, setIsDAP] = useState(false);
  const [isTaab, setIsTaab] = useState(false);

  useEffect(() => {
    const { isDap, isTaab } = getQueryParams();

    setIsDAP(isDap === 'true');
    setIsTaab(isTaab === 'true');
  }, []);

  return (
    <div className={styles.subheaderWrapper}>
      <p>
        {isTaab
          ? t('estimate_taab')
          : isDAP
          ? t('estimate_dap')
          : t('estimate_tsrp')}
        <button
          onClick={() => openDisclaimerModal(isTaab ? '11' : isDAP ? '9' : '7')}
          onKeyPress={e =>
            onEnterPress(e, () =>
              openDisclaimerModal(isTaab ? '11' : isDAP ? '9' : '7')
            )
          }
        >
          {isTaab ? 11 : isDAP ? 9 : 7}
        </button>
      </p>
      {!isMobile && <p> - {t('visit_dealer')}</p>}
    </div>
  );
};

export default Subheader;
