import BrandConstant from '../constants/Brands';

class BrandService {
  private brand = 'toyota';
  private readonly DEFAULT_BRAND = 'toyota';

  constructor() {
    this.setBrand();
  }

  public setBrand = (_brand?: string) => {
    this.brand = _brand ? _brand : this.DEFAULT_BRAND;
  };

  public getBrand = (service?: string) => {
    return service ? BrandConstant[this.brand][service] : this.brand;
  };
}

const brandService = new BrandService();

export default brandService;
