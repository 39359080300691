import BrandService from '../../services/BrandService';
import { displayCategories } from '../../constants/VehicleConstants';

import { Category } from '../../models/VIEW/ZipCodeStore';
import { IEFCSeriesLatestYearData } from '../../models/EFC/EFCSeriesV1';
import { getTrims, TrimData } from '../../services/TrimsService';
import { getColors, ColorData } from '../../services/ColorsService';
import { calcLowestMSRPForEachSeries } from '../../utils/vehicle';
import { getImageForSeries } from '../../utils/colors';
import { getSeries } from '../../services/SeriesService';
import { getSeriesYear } from '../../utils/series';

// calls series, vehicle and colors endpoints
export const createCategoriesForVehicleSelectPage = async (region: string) => {
  const filteredSeries = await getSeries(region);

  // get latest year for each series...
  const seriesForYear = getSeriesYear(filteredSeries);

  if (!seriesForYear) {
    throw new Error('Unable to find series for current year');
  }

  return processSeriesDataForVehicleSelectPage(seriesForYear, region);
};

// grabs vehicle data to compute augmentedMsrp
// and color data to display image...
export const processSeriesDataForVehicleSelectPage = async (
  _series: IEFCSeriesLatestYearData,
  region: string
) => {
  const categories: Category[] = initCategories(BrandService.getBrand());
  // populate categories
  const populatedCategories = populateCategories(categories, _series);

  // make colors and vehicles api calls in parallel
  const [series, years] = createListOfSeriesAndYears(populatedCategories);
  const calls: [Promise<ColorData>, Promise<TrimData>] = [
    getColors({ region, years }),
    getTrims({ region, years, series }),
  ];

  const [colorsDataResult, vehicleDataResult] = await Promise.all(calls);

  // update with new augmented msrp taking into account destination fee
  // and car jelly from
  return updateCategoriesWithMSRPAndCarJellies({
    year: years,
    colorsDataResult,
    vehicleDataResult,
    populatedCategories,
  });
};

const initCategories = (brand: string) => {
  const categories: Category[] = [];
  displayCategories[brand].forEach(category => {
    categories.push({
      code: category.code,
      order: category.order,
      series: [],
    });
  });
  return categories;
};

// populate categories with seriesForView
export const populateCategories = (
  categories: Category[],
  results: IEFCSeriesLatestYearData
): Category[] => {
  for (const seriesCode of Object.keys(results)) {
    const series = results[seriesCode];
    const item = {
      year: series.year.toString(),
      series: series.code,
      seriesName: series.title,
      baseMsrp: Number(series.price.starting_msrp),
      carJellyImage: '', // need to get this from colors api call
      augmentedMsrp: Number(series.price.starting_msrp), // recomputed later on
    };

    categories.forEach(category => {
      if (category.order?.includes(series.code)) {
        const index = category.order.indexOf(series.code);
        const newItem = { ...item, category: category.code };
        category.series[index] = newItem;
      }
    });
  }

  // cleaning up series array if a certain series is not returned by EFC
  categories.forEach(category => {
    category.series = category.series.filter(ser => !!ser);
  });

  return categories;
};

export const createListOfSeriesAndYears = (categories: Category[]) => {
  let seriesList = '';
  const years: string[] = []; // years for which we need to grab...
  for (const category of categories) {
    for (const series of category.series) {
      seriesList =
        seriesList === '' ? series.series : `${seriesList},${series.series}`;
      if (!years.includes(series.year)) {
        years.push(series.year);
      }
    }
  }
  return [seriesList, years.join(',')];
};

export const updateCategoriesWithMSRPAndCarJellies = ({
  populatedCategories,
  colorsDataResult,
  vehicleDataResult,
  year,
}: {
  year: string;
  vehicleDataResult: TrimData;
  colorsDataResult: ColorData;
  populatedCategories: Category[];
}) => {
  // convert year list array in result to 2d hash map
  const lowestMsrpByYearSeries = calcLowestMSRPForEachSeries(vehicleDataResult);

  return populatedCategories.map(category => {
    const newSeries = category.series.map(series => {
      // get image for series
      const carJellyImage = getImageForSeries({
        year,
        series: series.series,
        colorsDataResult,
      });

      return Object.assign({}, series, {
        augmentedMsrp: lowestMsrpByYearSeries[series.year][series.series],
        carJellyImage,
      });
    });
    return Object.assign({}, category, { series: newSeries });
  });
};
