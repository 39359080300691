import { useEffect } from 'react';
import i18n from 'i18next';

import { getMarketingData } from '../../../../services/WebServicesAPI';
import { OfferForView } from '../../../../stores/EstimatorPage/Factories/offerFactory';
import * as offerDetailsModalAnalytics from '../../../../utils/Analytics/offerDetailsModalAnalytics';
import * as PostMessageService from '../../../../services/PostMessageService';
import { useKeyPress } from '../../../../hooks/useKeyPress';
import { useStore } from '../../../../stores';
import { Offer } from 'models/PEMS/PEMMarketingData';

type GetMarketingType = {
  lang: string;
  offer: OfferForView;
  tdaCode: string;
  setIsOpen: (idx: boolean) => void;
  inputZipCode: string;
  setMarketingData: (idx: any) => void;
};
const getMarketing = async ({
  setMarketingData,
  inputZipCode,
  setIsOpen,
  tdaCode,
  offer,
  lang,
}: GetMarketingType) => {
  let offerImageSrc = '';
  const offerId = offer.tdaOfferId as string;

  const response = await getMarketingData({
    zipCode: inputZipCode,
    offerId: offerId,
    lang,
  });

  let selectedOffer: Offer | undefined;

  response.data.forEach(item => {
    const offer = item.offerBundle.offers.find(
      offer => offer.offerId === offerId
    );

    if (offer) {
      selectedOffer = offer;
    }
  });

  if (selectedOffer) {
    const {
      bullets: { bullet },
      description,
      disclaimers: { disclaimer },
      offerImage,
      offerImageAlt,
      title,
    } = selectedOffer;

    if (
      offerImage.indexOf('/assets/img/') !== -1 ||
      offerImage.indexOf('/inventoryswap/') !== -1
    ) {
      offerImageSrc = `https://www.buyatoyota.com${offerImage}`;
    } else if (offerImage.indexOf('/web_docs/img') !== -1) {
      offerImageSrc = `https://www.buyatoyota.com/${tdaCode}${offerImage}`;
    } else {
      offerImageSrc = offerImage;
    }

    setMarketingData({
      bullets: bullet,
      description,
      disclaimer,
      offerImage: offerImageSrc,
      offerImageAlt,
      title,
    });
  }

  setIsOpen(true);
  PostMessageService.sendHeight();
  return response;
};

type Props = {
  offer: OfferForView;
  isOpen: boolean;
  tdaCode: string;
  setIsOpen: (idx: boolean) => void;
  inputZipCode: string;
  setMarketingData: (idx: any) => void;
  displayOfferDetailsModal: boolean;
};
const useOfferDetailsModalHook = ({
  offer,
  isOpen,
  tdaCode,
  setIsOpen,
  inputZipCode,
  setMarketingData,
  displayOfferDetailsModal,
}: Props) => {
  const {
    modalStore: { closeOfferDetailsModal },
  } = useStore();
  const escapeKeyPressed = useKeyPress('Escape');
  const lang = i18n.language;

  useEffect(() => {
    if (isOpen && escapeKeyPressed) {
      closeOfferDetailsModal();
    }
  }, [isOpen, escapeKeyPressed, closeOfferDetailsModal]);

  // listen for change to displayOfferDetailsModal,
  // call api when toggled on, then in response
  // set modal to open
  useEffect(() => {
    if (displayOfferDetailsModal) {
      getMarketing({
        lang,
        offer,
        tdaCode,
        setIsOpen,
        inputZipCode,
        setMarketingData,
      });
      offerDetailsModalAnalytics.onOfferDetailsload();
      PostMessageService.sendScrollTop();
    } else if (!displayOfferDetailsModal) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayOfferDetailsModal, setIsOpen]);
};

export default useOfferDetailsModalHook;
