import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Button.module.scss';

type Props = {
  color?: 'red' | 'grey';
  onClick: React.MouseEventHandler;
  translate?: string;
  className?: string;
};

const Button: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    onClick,
    children,
    translate,
    className,
    color = 'red',
    ...remainingProps
  } = props;

  return (
    <button
      className={cx(
        styles.btn,
        styles.button,
        styles[`button-${color}`],
        className
      )}
      onClick={onClick}
      {...remainingProps}
    >
      {translate ? t(translate) : children}
    </button>
  );
};

export default Button;
