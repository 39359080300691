import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../stores';

import DevTools from '../../components/DevTools';
import Heading from './components/Heading';
import PageContainer from './components/PageContainer';
import PaymentOptions from './components/PaymentOptions';
import DisclaimerModal from './components/DisclaimerModal';
import ConfirmModal from './components/ConfirmModal';
import PageLoadAnalytics from './components/PageLoadAnalytics';

import * as PostMessageService from '../../services/PostMessageService';
import Toggle from './components/PaymentOptions/components/Toggle';
import PaymentDetails from './components/BottomSectionDesktop/Components/PaymentDetails';
import EstimatorStore from 'stores/EstimatorPage';
import PriceDetailsModal from './components/PriceDetailsModal';
import KbbTradeInModal from './components/KbbTradeInModal';
import Subheader from './components/Subheader';
import PaymentDisclaimer from './components/PaymentDisclaimer';
import styles from './Estimator.module.scss';

const Estimator: React.FC = () => {
  const { t } = useTranslation();

  const { estimatorStore, store } = useStore();
  const { isLoaded } = estimatorStore;
  const { setFirstPageLoaded, firstPageLoaded, isFetching } = store;

  // get data if landing directly on this page
  useEffect(() => {
    document.documentElement.classList.add('whiteBg');
    if (!firstPageLoaded) {
      estimatorStore.estimatorPageBootstrap();
      setFirstPageLoaded();
    }
    PostMessageService.sendHeight();

    return () => {
      document?.documentElement.classList.remove('whiteBg');

      EstimatorStore.isDirty = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching || !isLoaded) {
    return null;
  }

  return (
    <PageLoadAnalytics>
      <PageContainer>
        <DevTools />
        <section className={styles.estimatorSectionWrapper}>
          <div>
            <div className={styles.containerWrapper}>
              <Heading text={t('pe_title')} />
              <Toggle />
            </div>
            <Subheader />
          </div>
          <div className={styles.containerWrapper}>
            <PaymentOptions />
            <PaymentDetails />
          </div>
          <PaymentDisclaimer />
        </section>
        <DisclaimerModal />
        <PriceDetailsModal />
        <ConfirmModal />
        <KbbTradeInModal />
      </PageContainer>
    </PageLoadAnalytics>
  );
};

export default observer(Estimator);
