import { useEffect } from 'react';
import ParentLocationService from 'services/ParentLocationService';
import { useStore } from 'stores';
import * as estimatorAnalyticsService from '../utils/Analytics/estimatorAnalytics';

const useEstimatorPageAAPageLoad = (inView: boolean) => {
  const { estimatorStore } = useStore();

  const { isLoaded } = estimatorStore;

  useEffect(() => {
    if (isLoaded && inView) {
      if (ParentLocationService.getIsInventoryDetailsPage()) {
        estimatorAnalyticsService.fireAAComponentViewTag();
      } else {
        estimatorAnalyticsService.fireKMPEShownTag();
      }
    }
  }, [isLoaded, inView]);
};

export default useEstimatorPageAAPageLoad;
