import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { onEnterPress } from 'utils/onEnterPress';
import { useAutoFormatValueOnBlur } from '../../../../../../hooks/useAutoFormatOnBlur';
import { formatNumberWithCurrency } from '../../../../../../utils/numbers';
import styles from './Input.module.scss';

type Props = {
  disabled?: boolean;
  format?: boolean;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSupClick?: (event: any) => void;
  sup?: string;
  value: string;
  errorMessage?: string;
};
const Input: React.FC<Props> = ({
  disabled = false,
  format = true,
  label,
  onChange,
  onSupClick,
  sup,
  value,
  errorMessage,
}) => {
  const [onFocus, onBlur, formatedValue] = useAutoFormatValueOnBlur(
    format,
    formatNumberWithCurrency,
    value
  );

  const labelArray = (label ?? '').split(' ');
  return (
    <div className={styles.inputWrapper}>
      <span className={cx(styles.labelWrapper, { [styles.hasSup]: sup })}>
        {labelArray.map((item, i) => {
          return <span className={styles.labelItem} key={i}>{`${item} `}</span>;
        })}
        {sup && (
          <button
            className={styles.labelSup}
            onClick={onSupClick}
            onKeyPress={e => {
              onSupClick && onEnterPress(e, () => onSupClick(e));
            }}
          >
            {sup}
          </button>
        )}
      </span>
      <input
        className={cx(styles.input, {
          [styles.disabled]: disabled,
          [styles.hasError]: errorMessage,
        })}
        disabled={disabled}
        maxLength={5}
        onBlur={onBlur as React.EventHandler<React.FocusEvent>}
        onChange={onChange}
        onFocus={onFocus as React.EventHandler<React.FocusEvent>}
        type="text"
        value={formatedValue as string}
      />
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default observer(Input);
