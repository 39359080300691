import React from 'react';
import cx from 'classnames';
import { useStore } from 'stores';
import { observer } from 'mobx-react-lite';

import handlerStore from '../../../../../stores/EstimatorPage/estimatorPageHandlers';
import { OfferForView } from '../../../../../stores/EstimatorPage/Factories/offerFactory';
import styles from './Offer.module.scss';

type OfferProps = {
  offer: OfferForView;
};
const Offer: React.FC<OfferProps> = observer(({ offer }) => {
  const {
    modalStore: { openOfferDetailsModal },
  } = useStore();

  const _onChange = () => !offer.disabled && handlerStore.offerClick(offer);

  const checkboxHandler = (event: { keyCode: number }) => {
    if (!offer.disabled && (event.keyCode === 32 || event.keyCode === 13)) {
      handlerStore.offerClick(offer);
    }
  };

  return (
    <div className={styles.offerRoot}>
      <div className={styles.checkboxWrapper}>
        <div className={styles.label}>
          <input
            type="checkbox"
            checked={offer.selected}
            className={styles.input}
            aria-checked={offer.selected}
            readOnly
          />
          <button
            className={cx(styles.checkMark, {
              [styles.active]: offer.selected,
              [styles.disabled]: offer.disabled,
            })}
            onClick={_onChange}
            onKeyDown={checkboxHandler}
          />
          <button
            className={styles.checkBoxLabel}
            onClick={event => {
              event.preventDefault();
              openOfferDetailsModal(offer);
            }}
          >
            <div className={styles.labelText}>{offer.offerLabel}</div>
          </button>
        </div>
      </div>
    </div>
  );
});

export default Offer;
