import React, { MutableRefObject, ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import styles from './styles.module.scss';

export interface ModalBaseProps {
  children?: ReactNode;
  className?: string;
  darkOverlay?: boolean;
  isOpen: boolean;
  onClose?: () => void;
}

const ModalBase = React.forwardRef<HTMLDivElement, ModalBaseProps>(
  (
    { children, className, isOpen, onClose = () => {}, darkOverlay = false },
    ref
  ) => {
    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('overlayIsOpen');

        try {
          (document.querySelector('[role="dialog"]') as HTMLElement).focus();
        } catch (e) {
          console.log(e);
        }
      }
      return () => {
        document.body.classList.remove('overlayIsOpen');
      };
    });

    const ModalContent = (
      <section
        className={cx([styles.modal, { [styles.initialPosition]: ref }])}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalDialog}>
            <div
              className={cx([
                styles.overlay,
                { [styles.initialPosition]: ref },
                { [styles.darkOverlay]: darkOverlay },
              ])}
              onClick={onClose}
              role="presentation"
            />
            <div
              aria-modal={true}
              className={cx([className, styles.dialog])}
              tabIndex={-1}
              role="dialog"
            >
              <div className={styles.content}>{children}</div>
            </div>
          </div>
        </div>
      </section>
    );

    return isOpen
      ? ReactDOM.createPortal(
          ModalContent,
          ref
            ? (ref as MutableRefObject<HTMLDivElement>).current
            : document.body
        )
      : null;
  }
);

export default ModalBase;
