import { AccessoryDisclaimers } from 'models/VIEW/VehicleDetails';

const DISCLAIMER_REGEX = /\[[A-Za-z_]+\]/g;

export const getAccessoryDisclaimers = (marketingName: string): string[] => {
  const results: string[] = [];

  const disclaimers = marketingName.match(DISCLAIMER_REGEX);
  disclaimers?.forEach(disclaimer => {
    results.push(disclaimer.substring(1, disclaimer.length - 1));
  });
  return results;
};

export const getUniqueList = (value: string, index: number, self: string[]) => {
  return self.indexOf(value) === index;
};

export const getMarketingNameHTML = (
  marketingName: string,
  vehicleAccessoryDisclaimers: AccessoryDisclaimers[]
) => {
  if (!marketingName) {
    return '';
  }

  const disclaimers = marketingName.match(DISCLAIMER_REGEX);

  disclaimers?.forEach(disclaimer => {
    const disclaimerDetails = vehicleAccessoryDisclaimers.find(
      accessoryDisclaimer =>
        accessoryDisclaimer.code ===
        disclaimer.substring(1, disclaimer.length - 1)
    );

    marketingName = marketingName.replace(
      disclaimer,
      `<sup disclaimerId=${disclaimerDetails?.id}></sup>`
    );
  });

  return marketingName;
};
