import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './StatusBar.module.scss';
import { useStore } from '../../stores';

const StatusBar = () => {
  const { t } = useTranslation();
  const {
    store: { isStatusBarVisible, statusBarMsg, closeStatusBar },
  } = useStore();
  return (
    <div
      className={cx(styles.statusBar, { [styles.active]: isStatusBarVisible })}
    >
      <span>{t(statusBarMsg)}</span>
      <button
        className={cx(styles.closeButton, {
          [styles.buttonActive]: isStatusBarVisible,
        })}
        onClick={closeStatusBar}
      />
    </div>
  );
};

export default observer(StatusBar);
