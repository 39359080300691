/* Offer Factory. Create different offer objects for view */
import i18n from 'i18next';
import { observable } from 'mobx';

import {
  MiscOfferFromService,
  LeaseAndAprOfferFromService,
  OfferFromService,
} from '../../../models/PEMS/PEMOffers';
import { Subvention, mapBool2IsSubvented } from '../Utils/subventionUtils';

export const isMiscOffer = (
  offer: OfferFromService | MiscOfferFromService
): offer is MiscOfferFromService => {
  return (offer as MiscOfferFromService).miscTypeName !== undefined;
};

// no checks currently made for loyalty.
export const OfferType = {
  CUSTOMER_CASH: 'Customer Cash',
  LEASE: 'Lease',
  APR: 'APR',
  REBATE: 'REBATE',
  MILITARY: 'Military',
  COLLEGE: 'College',
  COMPLIMENTARY_FIRST_PAYMENT: 'Complimentary First Payment',
  TFS_LEASE_SUB_CASH: 'TFS Lease Subvention Cash',
};

export const isRateOffer = (offer: OfferForView) =>
  offer.offerType === OfferType.APR || offer.offerType === OfferType.LEASE;

export const ConditionalOfferTypes: Set<string> = new Set([
  'Loyalty',
  'Military',
  'College',
]);

export const isConditionalOffer = (offer: OfferForView) =>
  ConditionalOfferTypes.has(offer.offerType);
export const isNotConditionalOffer = (offer: OfferForView) =>
  !isConditionalOffer(offer);

export type OfferForView = {
  id: number;
  acquisitionFeeCapitalized?: boolean;
  leaseExampleId?: number;
  offerType: (typeof OfferType)[keyof typeof OfferType];
  isBase: boolean;
  offerLabel: string;
  compatibility: string[];
  endDate: string;
  startDate: string;
  tdaOfferId?: string;
  isAdvertised: boolean;
  amount?: number;
  isSubvented?: Subvention; // lease + apr bool, cash false, misc can be false, true or both.
  tfsContractList?: MiscOfferFromService['tfsContractList'];
  selected: boolean;
  disabled: boolean;
  isMisc?: boolean;
  configuredTier?: string;
  isConfiguredTierTerm?: string;
  downPayment?: number;
  dueAtSigning?: number;
  grossCapCost?: number;
  dealerGross?: number;
  isLeaseExample?: boolean;
  mileage?: number;
  modelCode?: string;
  payment?: number;
  rate?: number;
  subCash?: number;
  subventionCash?: number;
  terms?: number;
  tier?: string;
  totalMsrp?: number;
  acquisitionFee?: number;
  selectedModelCode?: string;
  isLowestAdvertisedLease?: boolean;
  modifiedSelected?: boolean;
  isRateOffer?: boolean;
  additionalCash?: OfferForView;
  bonusCash?: OfferForView;
  isCurrentModelCode: boolean;
  highTerm?: number;
  maxAmountPerMonth?: number;
};

const createAdditionalOffer = (
  cashOffers: OfferFromService[],
  miscOffers: MiscOfferFromService[],
  additionalCashDetailId?: number
) => {
  const offers: Array<OfferFromService | MiscOfferFromService> = [
    ...cashOffers,
    ...miscOffers,
  ];
  const filtered = offers.filter(item => item.id === additionalCashDetailId);
  const mappedOffers = filtered.map(offer => {
    if (isMiscOffer(offer)) {
      return mapMiscOffer(offer);
    } else {
      return mapCashOffer(offer);
    }
  });
  return mappedOffers[0];
};

const createBonusOffer = (
  miscOffers: MiscOfferFromService[],
  bonusCash?: number
): OfferForView => {
  return miscOffers
    .filter(item => item.amount === bonusCash)
    .map(mapMiscOffer)[0];
};

export const createLeaseOfferForView = ({
  offer,
  modelCode,
  cashOffers,
  miscOffers,
  regionCode,
  leaseExampleId,
}: {
  offer: LeaseAndAprOfferFromService;
  modelCode: string;
  cashOffers: OfferFromService[];
  miscOffers: MiscOfferFromService[];
  regionCode: string;
  leaseExampleId?: number;
}): OfferForView => {
  return observable({
    id: offer.id,
    leaseExampleId,
    offerType: OfferType.LEASE,
    isBase: true,
    offerLabel: `$${offer.targetPayment} | ${offer.highTerm} ${i18n
      .t('months')
      .toLowerCase()}`,
    acquisitionFee: offer.acquisitionFee,
    acquisitionFeeCapitalized: isAcqFeeCapitalized(regionCode, offer),
    compatibility: offer.compatibilityList || [],
    configuredTier: offer.configuredTier.tier,
    downPayment: isNaN(offer.downPayment) ? 2000 : offer.downPayment,
    dueAtSigning: offer.dueAtSigning,
    endDate: offer.endDate,
    grossCapCost: offer.grossCapCost,
    dealerGross: offer.dealerGross,
    isLeaseExample: offer.isLeaseExample,
    isAdvertised: offer.isAdvertised,
    isConfiguredTierTerm: offer.configuredTier.tier,
    mileage: offer.mileage || 12000, // temporary. Every lease should have mileage
    modelCode: offer.modelNumber || modelCode,
    payment: offer.targetPayment,
    rate: offer.regSubventedRcf,
    startDate: offer.startDate,
    subCash: offer.subventionCash || 0,
    terms: offer.highTerm,
    tdaOfferId: offer.tdaOfferId,
    tier: offer.tier,
    totalMsrp: offer.totalMsrp,
    selectedModelCode: modelCode,
    isCurrentModelCode: modelCode === offer.modelNumber,
    isLowestAdvertisedLease: offer.isLowestAdvertisedLease,
    isSubvented: mapBool2IsSubvented(offer.isSubvented),
    subventionCash: 0,
    isRateOffer: true,
    selected: false,
    disabled: false,
    additionalCash: createAdditionalOffer(
      cashOffers,
      miscOffers,
      offer.additionalCashDetailId
    ),
    bonusCash: createBonusOffer(miscOffers, offer.bonusCash),
    highTerm: offer.highTerm,
  });
};

export const isAcqFeeCapitalized = (
  regionCode: string,
  offer: LeaseAndAprOfferFromService
): boolean => {
  const isSubCashAppliedToDueAtSigning: boolean =
    Number(offer.dueAtSigning) <
    Math.round(Number(offer.targetPayment)) + Number(offer.acquisitionFee);

  const subventionCash = Number(offer.subventionCash || 0);

  if (!offer.isAdvertised) {
    return false;
  }

  return (
    regionCode === '600' ||
    Number(offer.dueAtSigning) -
      Math.round(Number(offer.targetPayment)) -
      (isSubCashAppliedToDueAtSigning ? subventionCash : 0) <
      Number(offer.acquisitionFee)
  );
};

export const createBuyOfferForView = ({
  offer,
}: {
  offer: LeaseAndAprOfferFromService;
}): OfferForView =>
  observable({
    id: offer.id,
    leaseExampleId: 0,
    offerType: OfferType.APR,
    isBase: true,
    offerLabel: `${offer.regSubventionRate} % | ${offer.highTerm} ${i18n
      .t('months')
      .toLowerCase()}`,
    compatibility: offer.compatibilityList || [],
    endDate: offer.endDate,
    isMisc: false,
    payment: offer.targetPayment,
    rate: offer.regSubventionRate,
    startDate: offer.startDate,
    subCash: offer.subventionCash || 0,
    tdaOfferId: offer.tdaOfferId,
    terms: offer.highTerm,
    tier: offer.tier,
    isAdvertised: offer.isAdvertised,
    isSubvented: mapBool2IsSubvented(offer.isSubvented),
    isRateOffer: true,
    selected: false,
    disabled: false,
    isCurrentModelCode: false,
    highTerm: offer.highTerm,
  });

export const mapCashOffer = (offer: OfferFromService): OfferForView => {
  return observable({
    id: offer.id,
    offerType: OfferType.CUSTOMER_CASH,
    isBase: true,
    offerLabel: `$${offer.combinedPerUnitCost.toLocaleString()} ${i18n.t(
      'cash_back'
    )}`,
    amount: offer.combinedPerUnitCost,
    compatibility: offer.compatibilityList || [],
    isAdvertised: offer.isAdvertised,
    endDate: offer.endDate,
    tdaOfferId: offer.tdaOfferId,
    startDate: offer.startDate,
    isMisc: false,
    isCurrentModelCode: false,
    selected: false,
    disabled: false,
  });
};

export const mapMiscOffer = (offer: MiscOfferFromService): OfferForView => {
  let theTranslatedLabel = offer.miscTypeName;
  if (i18n.language === 'es') {
    if (offer.miscTypeName === OfferType.COLLEGE) {
      theTranslatedLabel = 'reembolso por college';
    } else if (offer.miscTypeName === OfferType.MILITARY) {
      theTranslatedLabel = 'para militares';
    } else if (offer.miscTypeName === OfferType.COMPLIMENTARY_FIRST_PAYMENT) {
      theTranslatedLabel = 'Primer pago de cortesía';
    }
  }

  return observable({
    id: offer.id,
    offerType: offer.miscTypeName,
    offerLabel: `$${offer.combinedPerUnitCost.toLocaleString()} ${theTranslatedLabel}`,
    isMisc: true,
    amount: offer.combinedPerUnitCost,
    compatibility: offer.compatibilityList || [],
    isAdvertised: offer.isAdvertised,
    endDate: offer.endDate,
    tdaOfferId: offer.tdaOfferId,
    tfsContractList: offer.tfsContractList,
    startDate: offer.startDate,
    isBase: false,
    isSubvented: Subvention.NO, // subvention will be set earlier depending on tfsContractList compatibility with rate offer
    isCurrentModelCode: false,
    selected: false,
    disabled: false,
    maxAmountPerMonth: offer.maxAmountPerMonth,
  });
};
