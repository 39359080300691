import React from 'react';
import i18n, { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import handlerStore from '../../../../stores/EstimatorPage/estimatorPageHandlers';

import Dropdown from './components/Dropdown';
import Input from './components/Input';

import {
  MileageConstant,
  MileageConstantSET,
} from '../../../../constants/Mileage';
import {
  CreditScore,
  getCreditScoreConstant,
} from '../../../../constants/CreditScoreConstants';
import { formatNumber } from '../../../../utils/numbers';
import Offers from '../Offers';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';
import { useStore } from '../../../../stores';
import Cta from 'components/Cta';
import GlobalStore from 'stores/GlobalStore';
import styles from './PaymentOptions.module.scss';

// t from useTranslation
const createEstimtedCreditScoreOptions = (t: TFunction, regionCode: string) => {
  return getCreditScoreConstant(regionCode).map((creditScore: CreditScore) => ({
    label:
      regionCode === '500'
        ? `${creditScore.score} ${t(creditScore.label)}`
        : `${t(creditScore.label)} ${creditScore.score}`,
    value: creditScore.id,
  }));
};

const createMileageOptions = (regionCode: string) => {
  const mileageConstant =
    regionCode === '500' ? MileageConstantSET : MileageConstant;

  return mileageConstant.map(mileage => ({
    label: String(formatNumber(mileage)),
    value: mileage,
  }));
};

const defaultBuyTermOptions = [
  { label: `24 ${i18n.t('months')}`, value: 24 },
  { label: `36 ${i18n.t('months')}`, value: 36 },
  { label: `48 ${i18n.t('months')}`, value: 48 },
  { label: `60 ${i18n.t('months')}`, value: 60 },
  { label: `72 ${i18n.t('months')}`, value: 72 },
];

type Option = { label: string; value: number };

const createTermLengthsOptions = (tab: Tab, availableTerms?: number[]) => {
  if (tab === 'buy') {
    return defaultBuyTermOptions;
    // if tab === 'Lease' available Terms should be defined
  } else if (availableTerms) {
    return availableTerms.map(idx => ({
      label: `${idx} ${i18n.t('months')}`,
      value: idx,
    }));
  }
  return []; // sonarQube
};

const useCreateTermLengthOptions = (tab: Tab, availableTerms?: number[]) => {
  const [termLengthOptions, setOptions] = React.useState(
    createTermLengthsOptions(tab, availableTerms)
  );
  React.useEffect(() => {
    setOptions(createTermLengthsOptions(tab, availableTerms));
  }, [tab, availableTerms]);

  return termLengthOptions;
};

const PaymentOptions: React.FC = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: { payOptForm, selectedTab },
    modalStore: { openDisclaimerModal, openKbbTradeInModal },
    zipCodeStore: { regionCode },
  } = useStore();

  const {
    rate,
    terms,
    mileage,
    downPayment,
    tradeIn,
    availableTerms,
    creditScore,
    markup,
  } = payOptForm;
  const isBuyTab = selectedTab === 'buy';
  const termLengthsOptions = useCreateTermLengthOptions(
    selectedTab,
    availableTerms
  ) as Option[];
  const estimtedCreditScoreOptions = createEstimtedCreditScoreOptions(
    t,
    regionCode
  );

  const mileageOptions = createMileageOptions(regionCode);

  const selectedCreditScore =
    regionCode === '500' ? creditScore.score : creditScore.label;

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        {/* Cash down / Cash out of pocket input  */}
        <Input
          format
          label={t('cash_down')}
          onChange={handlerStore.onDownpaymentChange}
          value={String(downPayment)}
          sup={isBuyTab ? '' : '8'}
          onSupClick={openDisclaimerModal}
          errorMessage={
            GlobalStore.hasCashDownError ? t('cash_down_error') : undefined
          }
        />
        {/* Estimated credit score dropdown */}
        <Dropdown
          label={t('credit_score')}
          options={estimtedCreditScoreOptions}
          value={`${t<string>(`${selectedCreditScore}`)}`}
          onClick={handlerStore.onCreditChange}
          sup={'1'}
          onSupClick={openDisclaimerModal}
        />
        {/* Term length dropdown */}
        <Dropdown
          label={t('term_duration')}
          onClick={handlerStore.onTermsChange}
          options={termLengthsOptions}
          value={`${terms} ${t('months')}`}
        />
        {/* APR / Annual mileage dropdown */}
        {isBuyTab && (
          <Input
            disabled
            format={false}
            label={t('estimated_apr')}
            value={`${formatNumber((rate ?? 0) + (markup ?? 0))}%`}
          />
        )}
        {!isBuyTab && (
          <Dropdown
            label={t('annual_mileage')}
            onClick={handlerStore.onMileageChange}
            options={mileageOptions}
            value={formatNumber(mileage as number)}
          />
        )}
        <div className={styles.tradeInCtaWrapper}>
          <Cta onClick={openKbbTradeInModal} variant="withBlackUnderline">
            {t('trade_in_cta')}
          </Cta>
        </div>
        {/* Estimated trade in value input */}
        <div className={styles.tradeInWrapper}>
          <Input
            label={t('trade_in')}
            onChange={handlerStore.onTradeInChange}
            value={String(tradeIn)}
          />
        </div>
      </div>
      <Offers />
      <div className={styles.divider}></div>
    </div>
  );
};

export default observer(PaymentOptions);
