import React, { useState } from 'react';
import cx from 'classnames';

import useOutsideAlerter from '../../../../../../hooks/useOutsideAlerter';
import { onEnterPress } from 'utils/onEnterPress';
import styles from './Dropdown.module.scss';

type Option = {
  label: string;
  value: string | number;
};
type Props = {
  label?: string;
  onClick: (event: any) => void;
  onSupClick?: (event: any) => void;
  options: Option[];
  sup?: string;
  value: string | number;
};

const Dropdown: React.FC<Props> = ({
  label,
  onClick,
  onSupClick,
  options,
  sup,
  value,
}) => {
  const [isVisible, setVisible] = useState(false);
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => setVisible(false));
  const onDropdownClick = () => setVisible(!isVisible);
  const onMenuItemClick = (event: any) => {
    setVisible(false);
    onClick(event.target.value);
  };
  return (
    <div className={styles.root} ref={wrapperRef}>
      <span className={cx([styles.labelWrapper], { [styles.hasSup]: sup })}>
        {label}
        {sup && (
          <button
            className={styles.labelSup}
            onClick={onSupClick}
            onKeyPress={e => {
              onSupClick && onEnterPress(e, () => onSupClick(e));
            }}
          >
            {sup}
          </button>
        )}
      </span>
      <button
        onClick={onDropdownClick}
        className={styles.button}
        aria-expanded={isVisible}
      >
        <span
          className={cx([styles.carretDown, { [styles.carretUp]: isVisible }])}
        />
        <span className={styles.value}>{value}</span>
      </button>
      {isVisible && (
        <menu className={styles.menu}>
          {options.map(option => {
            return (
              <li
                key={option.value}
                value={option.value}
                onClick={onMenuItemClick}
                onKeyPress={e => {
                  onEnterPress(e, () => onMenuItemClick(e));
                }}
                className={styles.menuItem}
              >
                {option.label}
              </li>
            );
          })}
        </menu>
      )}
    </div>
  );
};

export default Dropdown;
