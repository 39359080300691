import React, { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import ValidationError from '../ValidationError';

import styles from './Input.module.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  className?: string;
}

const Input = React.forwardRef(
  (props: InputProps, ref: React.Ref<HTMLInputElement>) => {
    const { className, type = 'text', error, placeholder, ...rest } = props;

    return (
      <>
        <input
          type={type}
          ref={ref}
          className={cx([styles.input, className, { [styles.error]: !!error }])}
          placeholder={placeholder}
          {...rest}
        />
        <ValidationError error={error} />
      </>
    );
  }
);

export default Input;
