import React from 'react';

import styles from './ValidationError.module.scss';

type Props = { error?: string };
const ValidationError: React.FC<Props> = ({ error }) => (
  <>{!!error && <div className={styles.root}>{error}</div>}</>
);

export default ValidationError;
