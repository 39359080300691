import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { validateZipCodeInput } from '../../utils/zipCode';

import { getQueryParams } from '../../utils/history';

// validates there is a zipcode query string present
// if not redirect to zipCode checker page
const ZipCodeParamRedirect: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        const queryParams = getQueryParams();
        const { zip } = queryParams;
        if (zip && validateZipCodeInput(zip as string)) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          );
        }
      }}
    />
  );
};

export default ZipCodeParamRedirect;
