import React, { useState } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useStore } from '../../../../stores';
import {
  isRateOffer,
  OfferForView,
  OfferType,
} from '../../../../stores/EstimatorPage/Factories/offerFactory';
import { Bullet } from '../../../../models/PEMS/PEMMarketingData';

import {
  AprTitle,
  LeaseTitle,
  CustomerCashTitle,
  DefaultTitle,
} from './components/Titles';
import MarketingDetails from './components/MarketingDetails';

import * as PostMessageService from '../../../../services/PostMessageService';

import styles from './OfferDetailsModal.module.scss';
import Button from '../../../../modules/ZipCode/components/Button';
import useOfferDetailsModalHook from './useOfferDetailsModalHook';
import ModalBase from 'components/ModalBase';

type OfferDetailsProps = {
  offer: OfferForView;
};

const OfferDetailsModal: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    modalStore: {
      displayOfferDetailsModal,
      closeOfferDetailsModal,
      openContactDealerModal,
    },
    estimatorStore: { payOptForm },
    zipCodeStore: { inputZipCode, tdaCode },
  } = useStore();

  const [marketingData, setMarketingData] = useState({
    bullets: [] as Bullet[],
    disclaimer: [] as string[],
    description: '',
    offerImage: '',
    offerImageAlt: '',
  });

  const { carJellyImage, title } = payOptForm;

  useOfferDetailsModalHook({
    offer,
    isOpen,
    tdaCode,
    setIsOpen,
    inputZipCode,
    setMarketingData,
    displayOfferDetailsModal,
  });

  const scrollToDisclaimer = () => {
    if (ref.current !== null) {
      ref.current.scrollIntoView();
    }
  };

  const contactDealerOnclick = () => {
    closeOfferDetailsModal();
    openContactDealerModal(null, false);
  };

  const onCloseOfferModal = () => {
    closeOfferDetailsModal();
    PostMessageService.sendHeight();
  };

  return (
    <ModalBase isOpen={isOpen}>
      <div
        className={cx(styles.offerDetailsModal, 'modal-content', {
          [styles.open]: isOpen,
        })}
        role="dialog"
        id={`offer-details-popup-${offer.id}`}
        tabIndex={-1}
      >
        <div className={styles.modalHeader}>
          <div className={styles.closeModal}>
            <button
              className={styles.closeButton}
              onClick={onCloseOfferModal}
              aria-label="close popup"
            >
              <span className={styles.closeSpan} />
            </button>
          </div>
        </div>
        <div>
          {offer.offerType === OfferType.APR && <AprTitle offer={offer} />}
          {offer.offerType === OfferType.LEASE && <LeaseTitle offer={offer} />}
          {offer.offerType === OfferType.CUSTOMER_CASH && (
            <CustomerCashTitle offer={offer} />
          )}
          {offer.offerType !== OfferType.CUSTOMER_CASH &&
            offer.offerType !== OfferType.APR &&
            offer.offerType !== OfferType.LEASE && (
              <DefaultTitle offer={offer} />
            )}
        </div>
        <div className={styles.modalBody}>
          <div className={styles.bodyLeft}>
            <p>{marketingData.description}</p>
            <MarketingDetails
              marketingData={marketingData}
              scrollToDisclaimer={scrollToDisclaimer}
            />
            <div className={styles.buttonWrapper}>
              <Button onClick={contactDealerOnclick} className={styles.btn}>
                {t('contact_dealer')}
              </Button>
            </div>
          </div>
          <div className={styles.bodyRight}>
            <p className={styles.expDate}>
              {t('offer_details_modal.expires')} <span>{offer.endDate}</span>
            </p>
            {(offer.offerType === OfferType.CUSTOMER_CASH ||
              offer.offerType === OfferType.APR ||
              marketingData.offerImage !== '') && (
              <img
                className={styles.offerDetailImg}
                src={
                  offer.offerType === OfferType.CUSTOMER_CASH ||
                  isRateOffer(offer)
                    ? carJellyImage
                    : marketingData.offerImage
                }
                onError={event => {
                  event.currentTarget.src = 'images/blank_car_PE.png';
                }}
                alt={
                  offer.offerType === OfferType.CUSTOMER_CASH ||
                  isRateOffer(offer)
                    ? title
                    : marketingData.offerImageAlt
                }
              />
            )}
          </div>
        </div>
        <div className={styles.offerDisclaimer} ref={ref}>
          {marketingData.disclaimer.map(disclaimer => (
            <p key={disclaimer}>{disclaimer}</p>
          ))}
        </div>
      </div>
    </ModalBase>
  );
};

export default observer(OfferDetailsModal);
