import React from 'react';
import styles from './Modal.module.scss';

import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores';

type Props = {
  onClose?: () => void;
};
const ModalClose: React.FC<Props> = ({ onClose }) => {
  const {
    modalStore: { closeContactDealerModal },
  } = useStore();

  const onClickHandler = () => {
    if (onClose) {
      onClose();
    }
    closeContactDealerModal();
  };

  return (
    <div className={styles.closeButton}>
      <button onClick={onClickHandler} />
    </div>
  );
};

export default observer(ModalClose);
