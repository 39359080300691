import { getQueryParams } from '../../utils/history';

import * as AnalyticsService from './index';
import BrandService from '../../services/BrandService';
import { SeriesForView } from '../../models/VIEW/EstimatorStore';

export const fireLinkBlock = (series: SeriesForView, baseURI: string) => {
  let block = AnalyticsService.getToolBlock({
    link_url: baseURI,
    link_href: baseURI,
    link_text: series.seriesName,
    link_action: 'AA-link',
    series_msrp: series.baseMsrp,
    series_brand: BrandService.getBrand(),
    series_category: series.category,
    series_code: series.series,
    series_year: series.year,
  });

  block = extendWithGlobalBlock(block);
  AnalyticsService.fireAALink(block);
};

export const extendWithGlobalBlock = (block: any) => {
  const { zip } = getQueryParams();

  block.zipcode = zip;
  block.content_section = AnalyticsService.PAGE_TITLE_VEHICLE_SELECT;

  return block;
};
