import { Tab } from '../VIEW/ViewOffersTab';

export type PaymentsErrorResponse = {
  statusCode: number;
  errorCode?: number | boolean; // added by code
  message?: string; // added by code
  body: string;
};

type CalculateOffer = {
  compatibility: string[];
  advertised: boolean;
  offerType: string; // "APR"
  offerId: string; // "prius_apr_169704"
  isBase: true;
  type: string; // "RATE_INCENTIVE"
  rate: number; // 3.9
  term: number; // 72
  tier: string; // "1+"
};

export type Payments = {
  type: Tab;
  payment: number; // 344.26;
  acquisitionFee: number; // 0
  msrp: number; // 24725;
  downPayment: number; // 2000;
  tradeIn: number; // 0;
  markup: number; // 0;
  rateValue: number; // 2.9;
  tier: string; // '1+';
  term: number; // 72;
  model: string; // 'prius';
  modelYear: string; // '2019';
  trimCode: string; // '1221';
  pricingArea: string; // '001-CA';
  offerIds: string[]; // ["prius_apr_151058"]
  offerTypes: string; // ["APR"]
  isDssoRateApplied: boolean;
  securityDeposit: number;
  dueAtSigning: number;
  offers: CalculateOffer[];
};

type LowestPayment = {
  type: string; //  "lease"
  advertised: boolean;
  payment: number; // 248.98
  acquisitionFee: number; // 650
  residual: number; // 0.52
  residualValue: number; // 12994.28
  msrp: number; // 24989
  effectiveGrossCapCost: number; // 23955
  downPayment: number; // 1100
  tradeIn: number; // 0
  dueAtSigning: number; // 1998.98
  rateValue: number; // 0.00003
  markup: number; // 0
  tier: string; // "1+"
  term: number; // 36
  model: string; // "prius"
  modelYear: string; // "2019"
  trimCode: string; // "1221"
  offerIds: string[]; // ["prius_lease_151027"]
  offerTypes: string[]; // ["Lease"]
  securityDeposit: number; // 0
};

export type PEMDisclosures = {
  [idx: string]: string;
};

export type PEMCalculateResponse = {
  lowestPayment: LowestPayment;
  errorMessage?: string;
  disclosures: PEMDisclosures;
  errorCode?: string | number; // unsure as have not seen this in postman testing
  payments: Array<Payments | PaymentsErrorResponse>;
};

export const isPayment = (
  payment: Payments | PaymentsErrorResponse
): payment is Payments =>
  payment && (payment as PaymentsErrorResponse).statusCode === undefined;

export const isPaymentsErrorResponse = (
  payment: Payments | PaymentsErrorResponse
): payment is PaymentsErrorResponse =>
  (payment as PaymentsErrorResponse).statusCode !== undefined ||
  (payment as PaymentsErrorResponse).errorCode !== undefined;
